import { string, bool, func, object, oneOfType, number, array } from "prop-types";
import classnames from "classnames";

function Input({
  marginBottom,
  valid,
  label,
  isMediumFont,
  errorMessage,
  register,
  id,
  icon,
  elemRef,
  onBlurInput,
  autoComplete,
  isValsListPage,
  className = "",
  ...props
}) {
  const inputClass = classnames({
    "mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400": true,
    "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red":
      !valid,
    "pl-10": icon,
    [`mb-${marginBottom}`]: marginBottom,
    ["bg-gray-100"]: props.disabled,
    "w-[326px]": isValsListPage,
    [className]:true
  });

  const labelClass = classnames({
    "block text-sm leading-5 text-black mb-1": true,
    "font-medium": isMediumFont
  });

  const handleBlurInput = e => {
    onBlurInput(id, e.target.value, e);
  };

  return (
    <div className={classnames("relative", { "!mt-0": isValsListPage})}>
      {label && (
        <label htmlFor={id} className={labelClass}>
          {label}
        </label>
      )}
      <div className="relative rounded-md shadow-sm">
        {icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <img
              className="h-5 w-5 text-gray-400"
              src={icon}
              alt="input icon"
            />
          </div>
        )}
        <input
          id={id}
          className={inputClass}
          name={id}
          autoComplete={autoComplete || null}
          ref={register}
          onBlur={handleBlurInput}
          {...props}
        />

        {!valid && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <img src="/image/warning.svg" alt="Warning" title="Warning" />
          </div>
        )}
      </div>
      {!valid && (
        <p className="text-xs text-red-600 leading-none">
          {errorMessage}
        </p>
      )}
    </div>
  );
}

Input.propTypes = {
  isMediumFont: bool,
  id: string,
  valid: oneOfType([
    bool,
    array,
  ]),
  label: string,
  errorMessage: string,
  containerClass: string,
  register: oneOfType([func, object]),
  marginBottom: number,
  onBlurInput: func,
  isValsListPage: bool,
  className: string
};

Input.defaultProps = {
  id: "",
  valid: true,
  label: "",
  errorMessage: "",
  register: () => {},
  onBlurInput: () => {},
  isValsListPage: false
};

export default Input;
