import { initializeApollo } from "../apiService/apolloClient";
import { NEAREST_COMPANY_OFFICES } from "../graphql/company";

const CompanyService = {
  async getCompanyOfficesByLocation({ location, radius, organisationId, bankId }) {
    const apolloClient = initializeApollo();

    const response = await apolloClient.query({
      query: NEAREST_COMPANY_OFFICES,
      variables: {
        nearestOfficeFilter: {
          propertyLatitude: location.lat,
          propertyLongitude: location.lng,
          radius: radius,
          organisationId: organisationId,
          region: "Dublin",
          bankId: bankId
        }
      }
    });

    return response.data.nearestCompanyOffices;
  }
};

export default CompanyService;
