const FAKE_TYPES = [
  {
    label: "Full",
    value: "FULL",
  },
  {
    label: "Revaluation",
    value: "REVALUATION",
  },
  {
    label: "Final",
    value: "FINAL",
  },
];

export default FAKE_TYPES;
