import { gql } from "@apollo/client";

export const ALL_BANKS_QUERY = gql`
  {
    banks {
      id
      name
      address
      billingEmail
      billingPhone
      vatNumber
      companyNumber
      status
    }
  }
`;

export const BANK_BY_ID_QUERY = gql`
  query getBankById($id: Int!) {
    bank(id: $id) {
      id
      name
      address
      billingEmail
      billingPhone
      vatNumber
      companyNumber
      logo {
        id
        filename
        url
      }
      valuationsTeam {
        id
        role
        firstName
        lastName
        phone
        email
        active
      }
      linkedCompanies {
        id
        name
        scsiNo
        ipavNo
        ricsNo
        status
      }
      linkedOrganisations {
        id
        name
        address
        status
        billingEmail
        billingPhone
        vatNumber
        companyNumber
        directAssign
      }
      status
    }
  }
`;

export const CHANGE_BANK_STATUS = gql`
  mutation ChangeBankStatus($id: Int!, $status: BankStatus!) {
    changeBankStatus(id: $id, status: $status)
  }
`;

export const CREATE_BANK = gql`
  mutation createBank($input: BankCreate!) {
    createBank(input: $input) {
      id
      name
      logo {
        id
        filename
        url
      }
      address
      billingEmail
      billingPhone
      vatNumber
      companyNumber
      status
    }
  }
`;

export const UPDATE_BANK = gql`
  mutation updateBank($input: BankUpdate!) {
    updateBank(input: $input) {
      id
      name
      logo {
        id
        filename
        url
      }
      address
      billingEmail
      billingPhone
      vatNumber
      companyNumber
      status
    }
  }
`;

export const ALL_BANK_USERS_QUERY = gql`
  query bankUsers($bankId: Int!) {
    bankUsers(bankId: $bankId) {
      id
      role
      firstName
      lastName
      email
      active
    }
  }
`;

export const BANK_USER_QUERY = gql`
  query bankUser($id: Int!) {
    bankUser(id: $id) {
      id
      role
      firstName
      lastName
      phone
      email
      avatar {
        id
        filename
        url
      }
      active
    }
  }
`;

export const UPDATE_BANK_USER = gql`
  mutation updateBankUser($input: BankUserUpdate!) {
    updateBankUser(input: $input) {
      id
      role
      firstName
      lastName
      phone
      email
      avatar {
        id
        filename
        url
      }
      active
    }
  }
`;

export const CREATE_BANK_USER = gql`
  mutation createBankUser($input: BankUserCreate!) {
    createBankUser(input: $input) {
      id
      role
      firstName
      lastName
      phone
      email
      avatar {
        id
        filename
        url
      }
      active
    }
  }
`;

export const REMOVE_USER = gql`
  mutation deactivateBankUser($id: Int!) {
    deactivateBankUser(id: $id)
  }
`;

export const UNLINK_ORGANISATION_FROM_BANK = gql`
  mutation unlinkOrganisationFromBank($organisationId: Int!, $bankId: Int!) {
    unlinkOrganisationFromBank(organisationId: $organisationId, bankId: $bankId)
  }
`;

export const LINK_ORGANISATION_TO_BANK = gql`
  mutation linkOrganisationToBank(
    $organisationId: Int!
    $bankId: Int!
    $directAssign: Boolean
  ) {
    linkOrganisationToBank(
      organisationId: $organisationId
      bankId: $bankId
      directAssign: $directAssign
    )
  }
`;

export const ORGANISATIONS_AVAILABLE_TO_LINK = gql`
  query getOrganisationsAvailableToLinkToBank($bankId: Int!) {
    getOrganisationsAvailableToLinkToBank(bankId: $bankId) {
      id
      name
      address
      status
      billingEmail
      billingPhone
      vatNumber
      companyNumber
      directAssign
    }
  }
`;

export const LINK_COMPANY_TO_BANK = gql`
  mutation linkCompanyToBank($companyId: Int!, $bankId: Int!) {
    linkCompanyToBank(companyId: $companyId, bankId: $bankId)
  }
`;

export const UNLINK_COMPANY_FROM_BANK = gql`
  mutation unlinkCompanyFromBank($companyId: Int!, $bankId: Int!) {
    unlinkCompanyFromBank(companyId: $companyId, bankId: $bankId)
  }
`;

export const COMPANIES_AVAILABLE_TO_LINK = gql`
  query getCompaniesAvailableToLinkToBank($bankId: Int!) {
    getCompaniesAvailableToLinkToBank(bankId: $bankId) {
      id
      name
      scsiNo
      ipavNo
      ricsNo
      status
    }
  }
`;
