import { gql } from "@apollo/client";

export const ALL_COMPANIES_QUERY = gql`
  query companies($organisationIds: [Int]) {
    companies(organisationIds: $organisationIds) {
      id
      name
      scsiNo
      ipavNo
      ricsNo
      status
    }
  }
`;

export const COMPANY_OFFICE_BY_ID_QUERY = gql`
  query getCompanyOfficeById($id: Int!) {
    companyOffice(id: $id) {
      id
      name
      latitude
      longitude
      address
      eirCode
      street
      streetNumber
      premise
      city
      neighborhood
      region
      email
      phone
      mainOffice
    }
  }
`;
export const COMPANY_BY_ID_QUERY_STATISTICS = gql`
  query getCompanyById($id: Int!) {
    company(id: $id) {
      id
      name
      scsiNo
      ipavNo
      ricsNo
      logo {
        filename
        url
      }
      stamp {
        id
        filename
        url
      }
      status
      companyStatistics {
        totalValuationsYearly
        currentValuationsNumber
        slaBreaches
      }
      valuers {
        id
        role
        firstName
        lastName
        phone
        email
        startDate
        endDate
        psraNo
        psraDocument {
          id
          filename
        }
        active
      }
      insuranceDocuments {
        id
        type
        dateFrom
        dateTo
        insuranceDocument {
          id
          filename
        }
        active
      }
      linkedBanks {
        id
        name
        address
        billingEmail
        billingPhone
        vatNumber
        companyNumber
        status
      }
      offices {
        id
        name
        latitude
        longitude
        address
        city
        neighborhood
        region
        email
        phone
        mainOffice
      }
      organisation {
        id
        name
        address
        status
        billingEmail
        billingPhone
        vatNumber
        companyNumber
        directAssign
      }
    }
  }
`;

export const COMPANY_BY_ID_QUERY = gql`
  query getCompanyById($id: Int!) {
    company(id: $id) {
      id
      name
      scsiNo
      ipavNo
      ricsNo
      logo {
        filename
        url
      }
      stamp {
        id
        filename
        url
      }
      status
      valuers {
        id
        role
        firstName
        lastName
        phone
        email
        startDate
        endDate
        psraNo
        psraDocument {
          id
          filename
        }
        active
      }
      insuranceDocuments {
        id
        type
        dateFrom
        dateTo
        insuranceDocument {
          id
          filename
        }
        active
      }
      linkedBanks {
        id
        name
        address
        billingEmail
        billingPhone
        vatNumber
        companyNumber
        status
      }
      offices {
        id
        name
        latitude
        longitude
        address
        city
        neighborhood
        region
        email
        phone
        mainOffice
      }
      organisation {
        id
        name
        address
        status
        billingEmail
        billingPhone
        vatNumber
        companyNumber
        directAssign
      }
    }
  }
`;

export const CHANGE_COMPANY_STATUS = gql`
  mutation ChangeCompanyStatus($id: Int!, $status: CompanyStatus!) {
    changeCompanyStatus(id: $id, status: $status)
  }
`;

export const CREATE_COMPANY = gql`
  mutation createCompany($input: CompanyCreate!) {
    createCompany(input: $input) {
      id
      name
      logo {
        id
        filename
        url
      }
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany($input: CompanyUpdate!) {
    updateCompany(input: $input) {
      id
      name
      logo {
        id
        filename
        url
      }
    }
  }
`;

export const UPDATE_COMPANY_OFFICE = gql`
  mutation updateCompanyOffice($input: CompanyOfficeUpdate!) {
    updateCompanyOffice(Input: $input) {
      id
    }
  }
`;

export const NEAREST_COMPANY_OFFICES = gql`
  query nearestCompanyOffices($nearestOfficeFilter: NearestOfficeFilter!) {
    nearestCompanyOffices(nearestOfficeFilter: $nearestOfficeFilter) {
      officeId
      officeName
      officePhone
      officeEmail
      mainOffice
      officeLatitude
      officeLongitude
      organisationId
      organisationName
      companyId
      companyName
      distance
      organisationDirectAssign
      companyOfficeStatistics {
        officeCurrentValuationsNumber
        officeSlaBreaches
        officeTotalValuationsYearly
        orgCurrentValuationsNumber
        orgSlaBreaches
        orgTotalValuationsYearly
      }   
    }
  }
`;

export const GET_COMPANY_USERS = gql`
  query getCompanyUsers($companyId: Int!) {
    companyUsers(companyId: $companyId) {
      id
      role
      firstName
      lastName
      phone
      email
      startDate
      endDate
      psraNo
      active
    }
  }
`;

export const DELETE_COMPANY_OFFICE = gql`
  mutation deleteCompanyOffice($id: Int) {
    deleteCompanyOffice(id: $id)
  }
`;

export const CREATE_COMPANY_OFFICE = gql`
  mutation createCompanyOffice($Input: CompanyOfficeCreate!) {
    createCompanyOffice(Input: $Input) {
      id
      name
      latitude
      longitude
      distance
      address
      eirCode
      street
      streetNumber
      premise
      city
      neighborhood
      region
      email
      phone
      mainOffice
    }
  }
`;

export const CREATE_COMPANY_USER = gql`
  mutation createCompanyUser($input: CompanyUserCreate!) {
    createCompanyUser(input: $input) {
      id
      role
      firstName
      lastName
      phone
      email
      startDate
      endDate
      psraNo
      psraDocument {
        id
        filename
      }
      avatar {
        id
        filename
        url
      }
      active
    }
  }
`;

export const UPDATE_COMPANY_USER = gql`
  mutation updateCompanyUser($input: CompanyUserUpdate!) {
    updateCompanyUser(input: $input) {
      id
      role
      firstName
      lastName
      phone
      email
      startDate
      endDate
      psraNo
      psraDocument {
        id
        filename
      }
      avatar {
        id
        filename
        url
      }
      active
    }
  }
`;

export const GET_COMPANY_USER = gql`
  query companyUser($id: Int!) {
    companyUser(id: $id) {
      id
      role
      firstName
      lastName
      phone
      email
      startDate
      endDate
      psraNo
      psraDocument {
        id
        filename
      }
      avatar {
        id
        filename
        url
      }
      active
    }
  }
`;

export const REMOVE_COMPANY_USER = gql`
  mutation deactivateCompanyUser($id: Int!) {
    deactivateCompanyUser(id: $id)
  }
`;

export const CREATE_INSURANCE_DOCS = gql`
  mutation createCompanyInsuranceDocuments(
    $input: CompanyInsuranceDocumentsCreate!
  ) {
    createCompanyInsuranceDocuments(input: $input) {
      id
      type
      dateFrom
      dateTo
      insuranceDocument {
        id
        filename
      }
      active
    }
  }
`;

export const COMPANY_INSURANCE_DOCS = gql`
  query getCompanyInsuranceDocuments($companyId: Int) {
    getCompanyInsuranceDocuments(companyId: $companyId) {
      id
      type
      dateFrom
      dateTo
      insuranceDocument {
        id
        filename
      }
      active
    }
  }
`;
