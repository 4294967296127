import { gql } from '@apollo/client';

export const ALL_VALUATIONS_QUERY = gql`
    query ($statuses: [ValuationStatus], $companyIds: [Int], $organisationIds: [Int]) {
        valuations (statuses: $statuses, companyIds: $companyIds, organisationIds: $organisationIds) {
            id
            bank {
              id
              name
            }
            requestedBy {
              id
              role
              firstName
              lastName
            }
            underwriter {
              id
              role
              firstName
              lastName
            }
            customerName
            securityAddress
            organisation {
              id
              name
              address
              status
              directAssign
            }
            company {
              id
              name
              status
            }
            office {
              id
              name
              phone
              email
            }
            valuer {
              id
              role
              firstName
              lastName
              active
            }
            valuationType
            propertyAddress
            propertyNeighborhood
            propertyCity
            propertyRegion
            propertyCaseNumber
            propertyImageLinkUrl
            overdueStatus
            propertyImage {
                filename
                documentLocation
            }
            completedValuationDocument {
                documentLocation
                filename
              }
            dueDate
            status
            valuationAmount
            valuationCurrency
            broker
            brokerEmail
            notifyBroker
            sellingAgent
            sellingAgentEmail
            sellingAgentPhone
            expectedMarketValue
            inspectionDate
            notes
            slaPauseComment
            slaPaused
        }
    }
`

export const GET_VALUATIONS_BY_COMPANY_ID = gql`
   query ($companyIds: [Int]) {
        valuations (companyIds: $companyIds) {
            id
            bank {
              id
              name
            }
            company {
              id
              name
              status
            }
            completedValuationDocument {
                documentLocation
                filename
              }
            valuationHistoryList {
              id
              content
              status
              contentType
              contentBy
              createdDate
            }
            dueDate,
            createdDate,
            status
            valuationAmount
            valuationCurrency
            broker
            brokerEmail
            notifyBroker
            sellingAgent
            sellingAgentEmail
            sellingAgentPhone
            expectedMarketValue
            inspectionDate
            notes
            slaPauseComment
            slaPaused
        }
    }
`

export const GET_VALUATION_BY_ID_QUERY = gql`
    query getValuationById($id: Int!){
        valuation(id: $id) {
            id
            valuationAttachments {
              fileName
              documentLocation
            }
            bank {
                id
                name
            }
            organisation {
                id
                name
                address
            }
            office {
                id
                name
                phone
                email
                address
                latitude
                longitude
            }
            company {
                id
                name
                offices {
                    id
                    name
                    address
                    latitude
                    longitude
                }
            }
            requestedBy {
                id
                firstName
                lastName
            }
            underwriter {
                id
                firstName
                lastName
            }
            customerName
            securityAddress
            valuationHistoryList {
              id
              content
              status
              contentType
              contentBy
              createdDate
            }
            valuer {
                id
                role
                firstName
                lastName
                phone
                email
            }
            valuationType
            propertyLocationLatitude
            propertyLocationLongitude
            propertyAddress
            propertyNeighborhood
            propertyCity
            propertyRegion
            eircode
            propertyCaseNumber
            propertyImageLinkUrl
            overdueStatus
            propertyImage {
                documentLocation
                filename
            }
            completedValuationDocument {
                documentLocation
                filename
              }
            dueDate
            status
            valuationAmount
            valuationCurrency
            broker
            brokerEmail
            notifyBroker
            sellingAgent
            sellingAgentEmail
            sellingAgentPhone
            expectedMarketValue
            inspectionDate
            notes
            slaPauseComment
            slaPaused
        }
    }
`

export const CREATE_VALUATION = gql`
    mutation createValuation($input: ValuationCreate!) {
        createValuation(input: $input) {
            id
        }
    }
`;

export const CREATE_VALUATION_NOTE = gql`
    mutation createValuationHistory($input: ValuationHistoryCreate!) {
        createValuationHistory(input: $input) {
            id
            status
            content
            contentType
            contentBy
            valuationId
            createdDate
        }
    }
`;

export const UPDATE_VALUATION = gql`
  mutation updateValuation($input: ValuationUpdate!) {
    updateValuation(input: $input) {
      id
      bank {
          id
          name
      }
      organisation {
          id
          name
          address
      }
      office {
          id
          name
          phone
          email
          address
          latitude
          longitude
      }
      company {
          id
          name
          offices {
              id
              name
              address
              latitude
              longitude
          }
      }
      requestedBy {
          id
          firstName
          lastName
      }
      underwriter {
          id
          firstName
          lastName
      }
      valuer {
        id
        role
        firstName
        lastName
        phone
        email
      }
      customerName
      securityAddress
      valuationType
      propertyLocationLatitude
      propertyLocationLongitude
      propertyAddress
      propertyNeighborhood
      propertyCity
      propertyRegion
      propertyCaseNumber
      propertyImageLinkUrl
      propertyImage {
        documentLocation
        filename
      }
      completedValuationDocument {
        documentLocation
        filename
      }
      dueDate
      status
      valuationAmount
      valuationCurrency
      broker
      brokerEmail
      notifyBroker
      sellingAgent
      sellingAgentEmail
      sellingAgentPhone
      expectedMarketValue
      inspectionDate
      notes
      valuationAmount
      slaPauseComment
      slaPaused
    }
  }
`

export const CHANGE_VALUATION_STATUS = gql`
  mutation changeValuationStatus ($input: ValuationStatusChange!) {
    changeValuationStatus(input: $input)
  }`;

export const ALL_VALUATIONS_BY_USER = gql`
  query ($statuses: [ValuationStatus], $offset: Int!, $count: Int!) {
    valuationsByUserType (statuses: $statuses, offset: $offset, count: $count) {
      totalValuationsAmount
      valuations {
        id
        bank {
          id
          name
        }
        requestedBy {
          id
          role
          firstName
          lastName
        }
        underwriter {
          id
          role
          firstName
          lastName
        }
        customerName
        securityAddress
        organisation {
          id
          name
          address
          status
          directAssign
        }
        company {
          id
          name
          status
        }
        office {
          id
          name
          phone
          email
        }
        valuer {
          id
          role
          firstName
          lastName
          active
        }
        valuationType
        propertyAddress
        propertyNeighborhood
        propertyCity
        propertyRegion
        propertyCaseNumber
        propertyImageLinkUrl
        overdueStatus
        propertyImage {
          filename
          documentLocation
        }
        completedValuationDocument {
          documentLocation
          filename
        }
        dueDate
        status
        valuationAmount
        valuationCurrency
        broker
        brokerEmail
        notifyBroker
        sellingAgent
        sellingAgentEmail
        sellingAgentPhone
        expectedMarketValue
        inspectionDate
        notes
        slaPauseComment
        slaPaused
        valuationHistoryList {
          id
          content
          status
          contentType
          contentBy
          createdDate
        }
      }
    }
  }
`;

export const GET_VALUATIONS_BY_USER = gql`
  query ($statuses: [ValuationStatus], $offset: Int!, $count: Int!, $searchTerm: String, $slaStatuses: [SlaStatus], $organisationIds: [Int], $companyIds:[Int], $sort: ValuationSort) {
    valuationsSearch (statuses: $statuses, offset: $offset, count: $count, searchTerm: $searchTerm, slaStatuses: $slaStatuses, organisationIds: $organisationIds, companyIds: $companyIds, sort: $sort) {
      totalValuationsAmount
      valuations {
        id
        bank {
          name 
        }
        requestedBy {
          firstName 
          lastName
        }
        securityAddress
        organisation {
          name
        }
        company {
          name
        }
        valuer {
          firstName
          lastName
        }
        propertyAddress,
        propertyImageLinkUrl 
        overdueStatus
        completedValuationDocument {
          documentLocation
        }
        status 
        valuationAmount 
        sellingAgent
        inspectionDate
        slaPaused
        valuationHistoryList {
          status
          createdDate
        }
      }
    }
  }
`;

export const VALUATIONS_NUMBER = gql`
  query {
    valuationsNumbers {
      overdueCompletionValuations
      overdueInspectionValuations
      overdueNotAcceptedValuations
      pausedValuations
      valuationsByStatus {
        count
        status
      }
    }
  }
`;