import React, { useContext, useReducer } from "react";

const ModalContext = React.createContext();

const initialState = {
  open: false,
  callback: undefined,
  message: undefined,
  title: undefined,
  confirmButtonVariant: undefined,
  confirmButtonText: undefined,
  keepLive: false,
  hideCancel: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "openModal":
      return {
        ...state,
        open: true,
        callback: action.payload.callback,
        message: action.payload.message,
        title: action.payload.title,
        confirmButtonVariant: action.payload.confirmButtonVariant,
        confirmButtonText: action.payload.confirmButtonText,
        keepLive: action.payload.keepLive,
        hideCancel: action.payload.hideCancel
      };
    case "closeModal":
      return {
        ...state,
        open: false,
        callback: undefined,
        message: undefined,
        title: undefined,
        confirmButtonVariant: undefined,
        confirmButtonText: undefined,
        keepLive: false,
        hideCancel: false
      };
  }
};

const ModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const openModal = options => {
    dispatch({ type: "openModal", payload: options });
  };
  return (
    <ModalContext.Provider value={{ state, dispatch, openModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export function useModalContext() {
  return useContext(ModalContext);
}

export default ModalProvider;
