import { useLocalObservable } from "mobx-react-lite";

const CreateLoginStore = () => {
  const store = useLocalObservable(() => ({
    user: null,
    email: null,

    changeUser: (user) => {
      store.user = user;
    },
    changeEmail: (email) => {
      store.email = email;
    },
    resetUser: () => {
      store.user = null;
    },
    resetEmail: () => {
      store.email = null;
    },
  }));

  return store;
};

export default CreateLoginStore;
