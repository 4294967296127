import { useState } from "react";

import { useModalContext } from "hocs/ModalContext";

import Button from "components/Button/Button";
import Modal from "components/Modal/Modal";
import Error from "components/Error/Error";

/**
 * This is a reusable confirmation modal.
 * You should pass the necessary props, and it will render the modal.
 * Check the way of using it in src/scene/Bank-Settings/components/Heading/DetailsHeading.js
 */
const ConfirmationModal = () => {
  const { state, dispatch } = useModalContext();
  const [loading, setLoading] = useState(false);
  const [confirmError, setConfirmError] = useState(null);
  const {
    open,
    callback,
    confirmButtonVariant,
    confirmButtonText,
    title,
    message,
    keepLive,
    hideCancel
  } = state;

  const handleClose = () => {
    dispatch({ type: "closeModal" });
    setConfirmError(null);
    setLoading(false);
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);
      await callback?.();
      if (!keepLive) dispatch({ type: "closeModal" });
    } catch (e) {
      setConfirmError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal handleClose={handleClose} isOpen={open}>
      <div className="bg-white py-7 px-8 w-full max-w-modal">
        <div className="text-left">
          <h3
            className="text-lg leading-6 font-medium text-gray-900"
            id="modal-title"
          >
            {title || "Confirm"}
          </h3>
          <div className="mt-4 ">
            <p className="text-gray-500 leading-normal">{message}</p>
          </div>
          <Error errorMessage={confirmError} className={"mt-4"} fullWidth />
        </div>
        <div className="flex justify-end mt-4">
          {!hideCancel && (
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          )}
          <Button
            variant={confirmButtonVariant}
            className="ml-4"
            onClick={handleConfirm}
            loading={loading}
          >
            {confirmButtonText || "Confirm"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
