import classnames from "classnames";
import { number, string } from "prop-types";

export const SPINNER_SIZES = {
  XS: "xs",
  SMALL: "small",
  MEDIUM: "medium",
  BIG: "big",
};

const Spinner = ({ isLoad, children, marginTop, size }) => {
  let marginTopProperty = typeof marginTop == "number" ? `mt-${marginTop}` : "";

  let spinnerWrapClass = classnames({
    "w-full": true,
    "h-full flex items-center": !marginTop,
    [marginTopProperty]: true,
  });

  let spinnerClass = classnames({
    "mx-auto": true,
    "w-12": size == SPINNER_SIZES.XS,
    "w-16": size == SPINNER_SIZES.SMALL,
    "w-20": size == SPINNER_SIZES.MEDIUM,
    "w-28": size == SPINNER_SIZES.BIG,
  });

  if (isLoad) {
    return (
      <div className={spinnerWrapClass}>
        <img className={spinnerClass} src="/image/spinner.svg" alt="Spinner" />
      </div>
    );
  } else {
    return children;
  }
};

Spinner.propTypes = {
  marginTop: number,
  size: string,
};
Spinner.defaultProps = {
  size: SPINNER_SIZES.BIG,
};

export default Spinner;
