import React from "react";
import classnames from "classnames";

const Modal = ({
  isOpen,
  size = "lg",
  children,
  handleClose,
  allowOverflow
}) => {
  const containerClass = classnames({
    "inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full": true,
    "overflow-hidden": !allowOverflow,
    [`sm:max-w-[830px]`]: size === "xl",
    [`sm:max-w-lg`]: size === "lg",
    [`sm:max-w-sm`]: size === "sm"
  });

  const onClose = () => handleClose?.();
  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 z-20 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              onClick={onClose}
              className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"
              aria-hidden="true"
            />
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className={containerClass}>{children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
