export const ERROR_MESSAGES = {
  REQUIRED: "* Field required",
  MIN_LENGTH: "min length - 1",
  EMAIL: "email is not valid",
  NAME: "bank already exists!",
  PASSWORD: {
    PASSWORD_NOT_VALID: "password is not valid",
    PASSWORDS_DO_NOT_MATCH: "passwords do not match"
  },
  PHONE: "phone number is not valid",
  DATE: "date is not valid"
};

export const KEYS_PATH = {
  CREATION_DATE: {
    getValue: valuation => valuation.valuationHistoryList[0]?.createdDate,
    type: 'date'
  },
  RECENT_ACTIVITY: {
    getValue: valuation => valuation.valuationHistoryList[valuation.valuationHistoryList.length - 1]?.createdDate,
    type: 'date'
  },
  PROPERTY: {
    getValue: valuation => valuation.securityAddress,
    type: 'string'
  },
  ORGANISATION: {
    getValue: (valuation) => valuation?.organisation?.name || "",
    type: 'string'
  },
  OFFICE: {
    getValue: (valuation) => valuation?.company?.name || "",
    type: 'string'
  },
  VALUER: {
    getValue: (valuation) => valuation?.valuer ? `${valuation?.valuer?.firstName} ${valuation?.valuer?.lastName}` : "",
    type: 'string'
  },
  INSPECTION: {
    getValue: (valuation) => valuation?.inspectionDate || "",
    type: 'date'
  },
  REQUESTED_BY: {
    getValue: (valuation) => valuation.bank.name,
    type: 'string'
  },
  STATUS: {
    getValue: (valuation) => valuation.status,
    type: 'string'
  },
  VALUATION: {
    getValue: (valuation) => +valuation?.valuationAmount ? +valuation.valuationAmount : 0,
    type: 'number'
  },
  SELLING_AGENT: {
    getValue: (valuation) => valuation.sellingAgent,
    type: 'string'
  },
  DATE_INSTRUCTED: {
    getValue: (valuation) => valuation.valuationHistoryList.find((event) => event.status === "REQUESTED")?.createdDate ?? "",
    type: 'string'
  }
}

export const REGEX_FIELDS = {
  EMAIL:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD: {
    LOWERCASE: /(?=.*[a-z])/,
    UPPERCASE: /(?=.*[A-Z])/,
    DIGITS: /(?=.*[0-9])/,
    SPECIALS: /(?=.*[!@#$%^&*])/,
    LENGTH: /(?=.{8,})/,
    FULL: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
  },
  PHONE: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
};

export const POSTAL_REGEX =
  /(?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$/;

export function numberWithCommas(x) {
  if (!x) return;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const titlesOfPasswordsState = {
  isLettersNumber: 'Minimum 8 characters',
  isUppercase: 'Should contain uppercase letter',
  isLowerCase: 'Should contain lowercase letter',
  isDigit: 'Should contain a number',
  isSymbols: 'Should contain special character'
}

export const checkThePassword = (value) => {
  const isLettersNumber = value.length > 7;
  const isUppercase = /[A-Z]/.test(value);
  const isLowerCase = /[a-z]/.test(value);
  const isDigit = /\d/.test(value);
  const isSymbols = /\W/.test(value);

  return { isLettersNumber, isUppercase, isLowerCase, isDigit, isSymbols }
}

export const ERROR_LOADING_MESSAGES = {
  CLIENT: "Looks like something went wrong. Try refreshing the page.",
  SERVER: "Sorry, the server is temporarily unavailable",
  OFFICES: "Failed to load offices",
  MAP: "Map cannot be loaded right now, sorry."
};

export const REGISTER_TYPES = {
  TEXT_NO_REQUIRED: {
    minLength: {
      value: 1,
      message: ERROR_MESSAGES.MIN_LENGTH
    }
  },
  TEXT: {
    required: ERROR_MESSAGES.REQUIRED,
    minLength: {
      value: 1,
      message: ERROR_MESSAGES.MIN_LENGTH
    }
  },
  EMAIL: {
    required: ERROR_MESSAGES.REQUIRED,
    pattern: {
      value: REGEX_FIELDS.EMAIL,
      message: ERROR_MESSAGES.EMAIL
    }
  },
  EMAIL_NOT_REQUIRED: {
    pattern: {
      value: REGEX_FIELDS.EMAIL,
      message: ERROR_MESSAGES.EMAIL
    }
  },
  PHONE: {
    required: ERROR_MESSAGES.REQUIRED,
    pattern: {
      value: REGEX_FIELDS.PHONE,
      message: ERROR_MESSAGES.PHONE
    }
  }
};

export const ROLES = {
  BANK_ADMIN: "BANK_ADMIN",
  BANK_USER: "BANK_USER",
  ORGANISATION_ADMIN: "ORGANISATION_ADMIN",
  ORGANISATION_USER: "ORGANISATION_USER",
  COMPANY_ADMIN: "COMPANY_ADMIN",
  COMPANY_USER: "COMPANY_USER",
  SUPER_ADMIN: "SUPER_ADMIN"
};

export const STATUS_LIST = {
  ALL_OVERDUE: "All SLA Overdue",
  ALL: "All",
  ALL_BUT_APPROVED: "All but Approved",
  ASSIGNED: "Assigned",
  OVERDUE_INSPECTION: "Assigned Overdue",
  IN_PROGRESS: "In Progress",
  OVERDUE_COMPLETION: "In Progress Overdue",
  AWAITING_REVIEW: "Awaiting Review",
  APPROVED: "Approved",
  OVERDUE: "Overdue",
  CANCELLED: "Cancelled",
  CHANGE_REQUESTED: "Change Requested",
  DECLINED: "Declined",
  DRAFTS: "Draft",
  REQUESTED: "Requested",
  OVERDUE_NOT_ACCEPTED: "Requested Overdue",
  PAUSED: "Paused",
};

export const ORDER_LIST = {
  CREATED_DATE: {
    key: "CREATED_DATE",
    value: "Creation Date",
    title: "Creation Date"
  },
  PROPERTY: {
    key: "PROPERTY",
    value: "Property",
    title: "Property"
  },
  ORGANISATION: {
    key: "ORGANISATION",
    value: "Organisation",
    title: "Organisation"
  },
  OFFICE: {
    key: "OFFICE",
    value: "Company",
    title: "Company"
  },
  VALUER: {
    key: "VALUER",
    value: "Valuer",
    title: "Valuer"
  },
  REQUESTED_BY: {
    key: "REQUESTED_BY", // not used in table
    value: "Requested by",
    title: "Requested by"
  },
  STATUS: {
    key: "STATUS",
    value: "Status",
    title: "Status"
  },
  AMOUNT: {
    key: "AMOUNT",
    value: "Valuation",
    title: "Valuation, k"
  },
  INSPECTION_DATE: {
    key: "INSPECTION_DATE",
    value: "Inspection",
    title: "Inspection"
  },
  SELLING_AGENT: {
    key: "SELLING_AGENT", // not used in table
    value: "Selling agent",
    title: "Selling agent"
  }
};

export const ORDER_DIRECTION = {
  ASC: "ASC",
  DESC: "DESC"
}

export const VALUATION_LIST_PAGE_CONFIG = {
  [ROLES.SUPER_ADMIN]: {
    LISTED_STATUS: [
      STATUS_LIST.ALL_BUT_APPROVED,
      STATUS_LIST.ALL_OVERDUE,
      STATUS_LIST.ALL,
      STATUS_LIST.REQUESTED,
      STATUS_LIST.OVERDUE_NOT_ACCEPTED,
      STATUS_LIST.ASSIGNED,
      STATUS_LIST.OVERDUE_INSPECTION,
      STATUS_LIST.IN_PROGRESS,
      STATUS_LIST.OVERDUE_COMPLETION,
      STATUS_LIST.AWAITING_REVIEW,
      STATUS_LIST.APPROVED,
      STATUS_LIST.DRAFTS,
      STATUS_LIST.CHANGE_REQUESTED,
      STATUS_LIST.DECLINED,
      STATUS_LIST.PAUSED,
    ],
    LISTED_COLUMNS: [
      ORDER_LIST.PROPERTY,
      ORDER_LIST.ORGANISATION,
      ORDER_LIST.OFFICE,
      ORDER_LIST.VALUER,
      ORDER_LIST.STATUS,
      ORDER_LIST.AMOUNT,
      ORDER_LIST.CREATED_DATE
    ]
  },
  [ROLES.BANK_ADMIN]: {
    LISTED_STATUS: [
      STATUS_LIST.ALL_BUT_APPROVED,
      STATUS_LIST.ALL_OVERDUE,
      STATUS_LIST.ALL,
      STATUS_LIST.REQUESTED,
      STATUS_LIST.OVERDUE_NOT_ACCEPTED,
      STATUS_LIST.ASSIGNED,
      STATUS_LIST.OVERDUE_INSPECTION,
      STATUS_LIST.IN_PROGRESS,
      STATUS_LIST.OVERDUE_COMPLETION,
      STATUS_LIST.AWAITING_REVIEW,
      STATUS_LIST.APPROVED,
      STATUS_LIST.DRAFTS,
      STATUS_LIST.CHANGE_REQUESTED,
      STATUS_LIST.DECLINED,
      STATUS_LIST.PAUSED,
    ],
    LISTED_COLUMNS: [
      ORDER_LIST.PROPERTY,
      ORDER_LIST.ORGANISATION,
      ORDER_LIST.OFFICE,
      ORDER_LIST.VALUER,
      ORDER_LIST.INSPECTION_DATE,
      ORDER_LIST.STATUS,
      ORDER_LIST.AMOUNT,
      ORDER_LIST.CREATED_DATE
    ]
  },
  [ROLES.BANK_USER]: {
    LISTED_STATUS: [
      STATUS_LIST.ALL_BUT_APPROVED,
      STATUS_LIST.ALL_OVERDUE,
      STATUS_LIST.ALL,
      STATUS_LIST.REQUESTED,
      STATUS_LIST.OVERDUE_NOT_ACCEPTED,
      STATUS_LIST.ASSIGNED,
      STATUS_LIST.OVERDUE_INSPECTION,
      STATUS_LIST.IN_PROGRESS,
      STATUS_LIST.OVERDUE_COMPLETION,
      STATUS_LIST.AWAITING_REVIEW,
      STATUS_LIST.APPROVED,
      STATUS_LIST.DRAFTS,
      STATUS_LIST.CHANGE_REQUESTED,
      STATUS_LIST.DECLINED,
      STATUS_LIST.PAUSED,
    ],
    LISTED_COLUMNS: [
      ORDER_LIST.PROPERTY,
      ORDER_LIST.ORGANISATION,
      ORDER_LIST.OFFICE,
      ORDER_LIST.VALUER,
      ORDER_LIST.INSPECTION_DATE,
      ORDER_LIST.STATUS,
      ORDER_LIST.AMOUNT,
      ORDER_LIST.CREATED_DATE
    ]
  },
  [ROLES.ORGANISATION_ADMIN]: {
    LISTED_STATUS: [
      STATUS_LIST.ALL_BUT_APPROVED,
      STATUS_LIST.ALL_OVERDUE,
      STATUS_LIST.ALL,
      STATUS_LIST.REQUESTED,
      STATUS_LIST.OVERDUE_NOT_ACCEPTED,
      STATUS_LIST.ASSIGNED,
      STATUS_LIST.OVERDUE_INSPECTION,
      STATUS_LIST.IN_PROGRESS,
      STATUS_LIST.OVERDUE_COMPLETION,
      STATUS_LIST.AWAITING_REVIEW,
      STATUS_LIST.APPROVED,
      STATUS_LIST.REQUESTED,
      STATUS_LIST.CHANGE_REQUESTED,
      STATUS_LIST.DECLINED,
      STATUS_LIST.PAUSED,
    ],
    LISTED_COLUMNS: [
      ORDER_LIST.PROPERTY,
      ORDER_LIST.OFFICE,
      ORDER_LIST.VALUER,
      ORDER_LIST.INSPECTION_DATE,
      ORDER_LIST.STATUS,
      ORDER_LIST.AMOUNT,
      ORDER_LIST.CREATED_DATE
    ]
  },
  [ROLES.ORGANISATION_USER]: {
    LISTED_STATUS: [
      STATUS_LIST.ALL_BUT_APPROVED,
      STATUS_LIST.ALL_OVERDUE,
      STATUS_LIST.ALL,
      STATUS_LIST.REQUESTED,
      STATUS_LIST.OVERDUE_NOT_ACCEPTED,
      STATUS_LIST.ASSIGNED,
      STATUS_LIST.OVERDUE_INSPECTION,
      STATUS_LIST.IN_PROGRESS,
      STATUS_LIST.OVERDUE_COMPLETION,
      STATUS_LIST.AWAITING_REVIEW,
      STATUS_LIST.APPROVED,
      STATUS_LIST.REQUESTED,
      STATUS_LIST.CHANGE_REQUESTED,
      STATUS_LIST.DECLINED,
      STATUS_LIST.PAUSED,
    ],
    LISTED_COLUMNS: [
      ORDER_LIST.PROPERTY,
      ORDER_LIST.OFFICE,
      ORDER_LIST.VALUER,
      ORDER_LIST.INSPECTION_DATE,
      ORDER_LIST.STATUS,
      ORDER_LIST.AMOUNT,
      ORDER_LIST.CREATED_DATE
    ]
  },
  [ROLES.COMPANY_ADMIN]: {
    LISTED_STATUS: [
      STATUS_LIST.ALL_BUT_APPROVED,
      STATUS_LIST.ALL_OVERDUE,
      STATUS_LIST.ALL,
      STATUS_LIST.ASSIGNED,
      STATUS_LIST.OVERDUE_NOT_ACCEPTED,
      STATUS_LIST.IN_PROGRESS,
      STATUS_LIST.OVERDUE_INSPECTION,
      STATUS_LIST.AWAITING_REVIEW,
      STATUS_LIST.APPROVED,
      STATUS_LIST.REQUESTED,
      STATUS_LIST.OVERDUE_COMPLETION,
      STATUS_LIST.CHANGE_REQUESTED,
      STATUS_LIST.DECLINED,
      STATUS_LIST.PAUSED,
    ],
    LISTED_COLUMNS: [
      ORDER_LIST.PROPERTY,
      ORDER_LIST.OFFICE,
      ORDER_LIST.VALUER,
      ORDER_LIST.INSPECTION_DATE,
      ORDER_LIST.STATUS,
      ORDER_LIST.AMOUNT,
      ORDER_LIST.CREATED_DATE
    ]
  },
  [ROLES.COMPANY_USER]: {
    LISTED_STATUS: [
      STATUS_LIST.ALL_BUT_APPROVED,
      STATUS_LIST.ALL_OVERDUE,
      STATUS_LIST.ALL,
      STATUS_LIST.ASSIGNED,
      STATUS_LIST.OVERDUE_NOT_ACCEPTED,
      STATUS_LIST.IN_PROGRESS,
      STATUS_LIST.OVERDUE_INSPECTION,
      STATUS_LIST.AWAITING_REVIEW,
      STATUS_LIST.APPROVED,
      STATUS_LIST.REQUESTED,
      STATUS_LIST.OVERDUE_COMPLETION,
      STATUS_LIST.CHANGE_REQUESTED,
      STATUS_LIST.DECLINED,
      STATUS_LIST.PAUSED,
    ],
    LISTED_COLUMNS: [
      ORDER_LIST.PROPERTY,
      ORDER_LIST.OFFICE,
      ORDER_LIST.VALUER,
      ORDER_LIST.INSPECTION_DATE,
      ORDER_LIST.STATUS,
      ORDER_LIST.AMOUNT,
      ORDER_LIST.CREATED_DATE
    ]
  }
};

export const VALUATION_STATUS = {
  DRAFT: "DRAFT",
  REQUESTED: "REQUESTED",
  ASSIGNED: "ASSIGNED",
  DECLINED: "DECLINED",
  IN_PROGRESS: "IN_PROGRESS",
  OVERDUE: "OVERDUE",
  AWAITING_REVIEW: "AWAITING_REVIEW",
  CHANGE_REQUESTED: "CHANGE_REQUESTED",
  APPROVED: "APPROVED",
  CANCELLED: "CANCELLED",
  OVERDUE_NOT_ACCEPTED: "OVERDUE_NOT_ACCEPTED",
  OVERDUE_INSPECTION: "OVERDUE_INSPECTION",
  OVERDUE_COMPLETION: "OVERDUE_COMPLETION",
  PAUSED: "PAUSED"
};

export const VALUATION_STATUS_STEP = {
  DRAFT: 1,
  REQUESTED: 2,
  ASSIGNED: 3,
  CHANGE_REQUESTED: 6,
  DECLINED: 6,
  OVERDUE: 6,
  CANCELLED: 7,
  AWAITING_REVIEW: 8,
  IN_PROGRESS: 10,
  APPROVED: 10
};

export const VALUATION_REPORT_STATUS_ACTIONS = {
  [ROLES.BANK_ADMIN]: {
    VIEW: [
      VALUATION_STATUS.AWAITING_REVIEW,
      VALUATION_STATUS.APPROVED,
      VALUATION_STATUS.CHANGE_REQUESTED
    ],
    REQUEST_CHANGES: [VALUATION_STATUS.AWAITING_REVIEW],
    UPDATE_CHANGES: [],
    MODIFY: []
  },
  [ROLES.COMPANY_ADMIN]: {
    VIEW: [
      VALUATION_STATUS.DECLINED,
      VALUATION_STATUS.ASSIGNED,
      VALUATION_STATUS.IN_PROGRESS,
      VALUATION_STATUS.OVERDUE,
      VALUATION_STATUS.AWAITING_REVIEW,
      VALUATION_STATUS.CHANGE_REQUESTED,
      VALUATION_STATUS.APPROVED,
      VALUATION_STATUS.CANCELLED
    ],
    REQUEST_CHANGES: [],
    UPDATE_CHANGES: [VALUATION_STATUS.CHANGE_REQUESTED],
    MODIFY: [VALUATION_STATUS.ASSIGNED, VALUATION_STATUS.IN_PROGRESS, VALUATION_STATUS.CHANGE_REQUESTED]
  },
  [ROLES.COMPANY_USER]: {
    VIEW: [
      VALUATION_STATUS.DECLINED,
      VALUATION_STATUS.ASSIGNED,
      VALUATION_STATUS.IN_PROGRESS,
      VALUATION_STATUS.OVERDUE,
      VALUATION_STATUS.AWAITING_REVIEW,
      VALUATION_STATUS.CHANGE_REQUESTED,
      VALUATION_STATUS.APPROVED,
      VALUATION_STATUS.CANCELLED
    ],
    REQUEST_CHANGES: [],
    UPDATE_CHANGES: [VALUATION_STATUS.CHANGE_REQUESTED],
    MODIFY: [VALUATION_STATUS.ASSIGNED, VALUATION_STATUS.IN_PROGRESS, VALUATION_STATUS.CHANGE_REQUESTED]
  },
  [ROLES.ORGANISATION_ADMIN]: {
    VIEW: [
      VALUATION_STATUS.IN_PROGRESS,
      VALUATION_STATUS.OVERDUE,
      VALUATION_STATUS.AWAITING_REVIEW,
      VALUATION_STATUS.CHANGE_REQUESTED,
      VALUATION_STATUS.APPROVED,
      VALUATION_STATUS.CANCELLED
    ],
    REQUEST_CHANGES: [],
    UPDATE_CHANGES: [],
    MODIFY: []
  },
  [ROLES.ORGANISATION_USER]: {
    VIEW: [
      VALUATION_STATUS.ASSIGNED,
      VALUATION_STATUS.DECLINED,
      VALUATION_STATUS.IN_PROGRESS,
      VALUATION_STATUS.OVERDUE,
      VALUATION_STATUS.AWAITING_REVIEW,
      VALUATION_STATUS.CHANGE_REQUESTED,
      VALUATION_STATUS.APPROVED,
      VALUATION_STATUS.CANCELLED
    ],
    REQUEST_CHANGES: [],
    UPDATE_CHANGES: [],
    MODIFY: []
  },
  [ROLES.BANK_USER]: {
    VIEW: [
      VALUATION_STATUS.AWAITING_REVIEW,
      VALUATION_STATUS.APPROVED,
      VALUATION_STATUS.CHANGE_REQUESTED
    ],
    REQUEST_CHANGES: [VALUATION_STATUS.AWAITING_REVIEW],
    UPDATE_CHANGES: [],
    MODIFY: []
  },
  [ROLES.SUPER_ADMIN]: {
    VIEW: "ALL",
    REQUEST_CHANGES: "ALL",
    UPDATE_CHANGES: "ALL",
    MODIFY: "ALL"
  },
  DEFAULT: {
    VIEW: [
      VALUATION_STATUS.IN_PROGRESS,
      VALUATION_STATUS.APPROVED,
      VALUATION_STATUS.CHANGE_REQUESTED,
      VALUATION_STATUS.AWAITING_REVIEW
    ],
    REQUEST_CHANGES: [],
    UPDATE_CHANGES: [VALUATION_STATUS.CHANGE_REQUESTED],
    MODIFY: [VALUATION_STATUS.IN_PROGRESS]
  }
};

export function streetViewUrl(valuation) {
  return (
    "https://maps.googleapis.com/maps/api/streetview?" +
    "size=512x512" +
    "&location=" +
    valuation.securityAddress
    +
    "&fov=60&pitch=0" +
    "&key=AIzaSyDc8t7weHAFQHfR2SYt1rBt9dEJ3r8HDvA"
  );
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
