import { gql } from "@apollo/client";

export const VALUATION_TEMPLATE_QUERY = gql`
  query valuationForm($id: Int!) {
    valuationForm(id: $id) {
      id
      content
      active
      bankId
      panels {
        name
        panelBreak
        fieldsGroups {
          fieldGroupUI
          meta
        }
        fields {
          fieldType
          html
          fieldUI
          options
          placeholder
          group
          defaultCheckedIndex
          defaultValue
          label
          meta
          icon
          subPanel
          name
          validation {
            id
            validationType
            parameters {
              id
              type
              value
            }
          }
        }
        subPanels {
          subPanelUI
          meta
          subPanelBreak
        }
      }
    }
  }
`;

export const CREATE_VALUATION_FORM_VALUES = gql`
  mutation createValuationFormValues($input: ValuationFormValuesCreate!) {
    createValuationFormValues(input: $input) {
      id
      valuationId
      valuationFormValues {
        id
        fieldUI
        value
      }
      revalidation {
        id
        fieldUI
        value
    }
    }
  }
`;

export const UPDATE_VALUATION_FORM_VALUES = gql`
  mutation updateValuationFormValues($input: ValuationFormValuesUpdate!) {
    updateValuationFormValues(input: $input) {
      id
      valuationId
      valuationFormValues {
        id
        fieldUI
        value
      }
      revalidation {
        id
        fieldUI
        value
    }
    }
  }
`;

export const GET_VALUATION_FORM_VALUES = gql`
  query valuationFormValues($id: Int!) {
    valuationFormValues(id: $id) {
      id
      valuationId
      valuationFormValues {
        id
        fieldUI
        value
      }
      revalidation {
        id
        fieldUI
        value
    }
    }
  }
`;
