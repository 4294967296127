import React from "react";
import classnames from "classnames";
import { bool, func, node, oneOf, string } from "prop-types";

function Button({
  children,
  className,
  variant,
  type,
  onClick,
  size,
  isFullWidth,
  isTextCenter,
  loading,
  disabled,
  testId
}) {
  const classesButton = classnames({
    "inline-flex items-center border border-transparent font-normal rounded-md focus:outline-none focus:border-accent-blue focus:shadow-outline-indigo transition ease-in-out duration-150": true,
    "text-white bg-accent-blue hover:bg-opacity-75": variant === "primary",
    "bg-white text-black border border-gray-300 hover:border-black":
      variant === "secondary",
    "inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm":
      variant === "warning",

    "bg-white text-overdue-red border border-overdue-red hover:border-opacity-50 hover:text-opacity-75 ":
      variant === "destructive",
    "px-4 py-2 text-base leading-6": size === "normal",
    "px-4 py-2 text-sm leading-6": size === "medium",
    "px-4 py-2 text-xs": size === "small",
    "w-full": isFullWidth,
    "justify-center": isTextCenter,
    [className]: className,
    "opacity-50 cursor-not-allowed": disabled
  });

  const classesWrap = classnames({
    "inline-flex rounded-md": true,
    "w-full": isFullWidth
  });

  return (
    <div className={classesWrap}>
      <button
        disabled={disabled}
        onClick={onClick}
        type={type}
        className={classesButton}
        data-testid={testId}
      >
        {loading ? (
          <img
            src={
              variant === "secondary"
                ? "/image/loading-btn-blue.svg"
                : "/image/loading-btn.svg"
            }
          />
        ) : (
          children
        )}
      </button>
    </div>
  );
}

Button.propTypes = {
  children: node.isRequired,
  className: string,
  variant: oneOf(["primary", "secondary", "destructive"]),
  onClick: func,
  size: string,
  type: oneOf(["button", "submit", "reset"]),
  isFullWidth: bool,
  isTextCenter: bool,
  loading: bool
};

Button.defaultProps = {
  variant: "primary",
  size: "normal",
  type: "button",
  onClick: () => {},
  isFullWidth: false,
  isTextCenter: false,
  loading: false
};

export default Button;
