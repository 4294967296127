import { useLocalObservable } from "mobx-react-lite";

import CompanyService from "../service/company-service";
import ValuationService from "../service/valuation-service";
import { ERROR_LOADING_MESSAGES } from "../constants";
import { getEmptyFields } from "../helpers";

export const FORM_INPUT_IDS = {
  VALUATION_TYPE: "valuationType",
  BROKER: "broker",
  BROKER_EMAIL: "brokerEmail",
  NOTIFY_BROKER: "notifyBroker",
  SECURITY_ADDRESS: "securityAddress",
  DUE_DATE: "dueDate",
  INSPECTION_DATE: "inspectionDate",
  CUSTOMER_NAME: "customerName",
  NAME: "sellingAgent",
  EMAIL: "sellingAgentEmail",
  PHONE: "sellingAgentPhone",
  NOTES: "notes",
  EXPECTED_MARKET_VALUE: "expectedMarketValue",
  PROPERTY_ADDRESS: "propertyAddress",
  ORGANIZATION_ID: "organisationId",
  COMPANY_ID: "companyId",
  OFFICE_ID: "companyOfficeId",
  UNDERWRITER_ID: "underwriterId",
  PROPERTY_LATITUDE: "propertyLocationLatitude",
  PROPERTY_LONGITUDE: "propertyLocationLongitude",
  PROPERTY_PREMISE: "propertyPremise",
  PROPERTY_EIR_CODE: "propertyEirCode",
  PROPERTY_STREET_NUMBER: "streetNumber",
  PROPERTY_STREET: "street",
  PROPERTY_NEIGHBORHOOD: "propertyNeighborhood",
  PROPERTY_CITY: "propertyCity",
  PROPERTY_REGION: "propertyRegion",
  PROPERTY_CASE_NUMBER: "propertyCaseNumber",
  VALUATION_ID: "valuationId",
  PROPERTY_IMAGE_FILENAME: "propertyImageFilename",
  PROPERTY_IMAGE_URL: "propertyImageUrl",
  COMPLETED_VALUATION_DOCUMENT_FILENAME: "completedValuationDocumentFilename",
  COMPLETED_VALUATION_DOCUMENT_URL: "completedValuationDocumentUrl",
  ATTACHED_FILES: "attachedFiles"
};

function formatAddressComponents(array) {
  const formattedObject = {};
  array.forEach(item => (formattedObject[item.types[0]] = item.long_name));

  return formattedObject;
}

const CreateValuationStore = () => {
  return useLocalObservable(() => ({
    isEditMode: false,
    creationFormStep: 1,
    offices: null,
    isCorrectAddress: true,
    types: null,
    filters: null,
    valuationStatus: null,
    loading: false,
    underwriters: null,
    selectedOptionType: null,
    position: null,
    locationData: null,
    selectedOffice: null,
    officesErrorMessage: false,
    typesErrorMessage: false,

    data: getEmptyFields(FORM_INPUT_IDS),

    resetValuation() {
      this.resetErrors();
      this.isCorrectAddress = true;
      this.isEditMode = false;
      this.locationData = null;
      this.creationFormStep = 1;
      this.offices = null;
      this.underwriters = null;
      this.types = null;
      this.loading = false;
      this.position = null;
      this.selectedOffice = null;
      this.filters = null;
      this.data = getEmptyFields(FORM_INPUT_IDS);
    },

    setLoading(value) {
      this.loading = value;
    },

    setCurrentValuation(valuation) {
      this.changePropertyAddress(valuation?.propertyAddress);
      this.creationFormStep = 3;
      this.isEditMode = true;
      this.selectedOptionType = "company"; // hardcoded, we will need to extract this info from Valuation Data
      this.data[FORM_INPUT_IDS.UNDERWRITER_ID] = valuation?.underwriter?.id;
      this.data[FORM_INPUT_IDS.ORGANIZATION_ID] = valuation?.organisation?.id;
      this.data[FORM_INPUT_IDS.COMPANY_ID] = valuation?.company?.id;
      this.data[FORM_INPUT_IDS.OFFICE_ID] = valuation?.office?.id;
      this.valuationStatus = valuation?.status;
      this.data[FORM_INPUT_IDS.SECURITY_ADDRESS] = valuation?.securityAddress;
      this.data[FORM_INPUT_IDS.DUE_DATE] = valuation?.dueDate;
      this.data[FORM_INPUT_IDS.NAME] = valuation?.sellingAgent;
      this.data[FORM_INPUT_IDS.CUSTOMER_NAME] = valuation?.customerName;
      this.data[FORM_INPUT_IDS.EMAIL] = valuation?.sellingAgentEmail;
      this.data[FORM_INPUT_IDS.PHONE] = valuation?.sellingAgentPhone;
      this.data[FORM_INPUT_IDS.EXPECTED_MARKET_VALUE] =
        valuation?.expectedMarketValue;
      this.data[FORM_INPUT_IDS.NOTES] = valuation?.notes;
      this.data[FORM_INPUT_IDS.VALUATION_TYPE] = valuation?.valuationType;
      this.data[FORM_INPUT_IDS.PROPERTY_LATITUDE] =
        valuation?.propertyLocationLatitude;
      this.data[FORM_INPUT_IDS.PROPERTY_LONGITUDE] =
        valuation?.propertyLocationLongitude;
      this.position = {
        location: {
          lat: Number(valuation?.propertyLocationLatitude),
          lng: Number(valuation?.propertyLocationLongitude)
        }
      };
      this.data[FORM_INPUT_IDS.PROPERTY_ADDRESS] = valuation?.propertyAddress;
      this.data[FORM_INPUT_IDS.PROPERTY_NEIGHBORHOOD] =
        valuation?.propertyNeighborhood;
      this.data[FORM_INPUT_IDS.PROPERTY_CITY] = valuation?.propertyCity;
      this.data[FORM_INPUT_IDS.PROPERTY_REGION] = valuation?.propertyRegion;
      this.data[FORM_INPUT_IDS.PROPERTY_EIR_CODE] = valuation.propertyEirCode;
      this.locationData = {
        administrative_area_level_1: valuation?.propertyRegion
      };
      this.data[FORM_INPUT_IDS.PROPERTY_CASE_NUMBER] =
        valuation?.propertyCaseNumber;

      this.data[FORM_INPUT_IDS.BROKER] =
        valuation?.broker;
      this.data[FORM_INPUT_IDS.BROKER_EMAIL] =
        valuation?.brokerEmail;

      this.data[FORM_INPUT_IDS.NOTIFY_BROKER] =
        valuation?.notifyBroker || true;

      this.data[FORM_INPUT_IDS.VALUATION_ID] = valuation?.id;
      this.selectedOptionType = valuation?.office ? "company" : "organisation";
      this.selectedOffice = {
        id: valuation?.office?.id || null,
        officeLatitude: Number(valuation?.office?.latitude) || "",
        officeLongitude: Number(valuation?.office?.longitude) || "",
        organisationName: valuation?.organisation?.name || "Independent",
        companyId: valuation?.company?.id || "",
        companyName: valuation?.company?.name || "-",
        officeName: valuation?.office?.name || "-",
        officePhone: valuation?.office?.phone || "-",
        officeEmail: valuation?.office?.email || "-"
      };

      this.getValuationTypes();
      this.getUnderwriters(valuation.bank?.id);
      this.getOffices(valuation.bank?.id);
    },

    setFilters(data) {
      this.filters = data;
    },

    resetErrors() {
      this.officesErrorMessage = false;
      this.typesErrorMessage = false;
    },

    changeProperty(property, value) {
      this.data[property] = value;
    },

    changePropertyAddress(address) {
      this.data[FORM_INPUT_IDS.PROPERTY_ADDRESS] = address;
    },
    changePosition(position, address_components, isCorrectAddress) {
      this.creationFormStep = 2;
      this.position = position;
      this.data[FORM_INPUT_IDS.PROPERTY_LATITUDE] = position.location.lat;
      this.data[FORM_INPUT_IDS.PROPERTY_LONGITUDE] = position.location.lng;

      this.data[FORM_INPUT_IDS.PROPERTY_NEIGHBORHOOD] = address_components[3].long_name;
      this.data[FORM_INPUT_IDS.PROPERTY_CITY] = address_components[4].long_name;
      this.data[FORM_INPUT_IDS.PROPERTY_REGION] = address_components[5].long_name;

      this.locationData = formatAddressComponents(address_components);
      if (!isCorrectAddress) {
        this.isCorrectAddress = false;
        this.offices = [];
      } else {
        this.getOffices(this.bankId, this.locationData);
      }
    },

    changeOffice({ companyId, officeId, organisationId }) {
      // Is we are assigning to a office, we have to check if that office can be directly assigned.
      // If it can't be directly assigned, we have to assign it to the org instead
      let officeCanBeDirectAssigned = false;
      if (officeId) {
        const selectedOffice = this.offices.find(
          office => office.officeId === officeId
        );
        if (selectedOffice.organisationDirectAssign == null || selectedOffice.organisationDirectAssign) {
          officeCanBeDirectAssigned = true;
        }
      }

      this.creationFormStep = 3;
      this.data[FORM_INPUT_IDS.ORGANIZATION_ID] = organisationId;
      this.data[FORM_INPUT_IDS.COMPANY_ID] = companyId || null;
      this.data[FORM_INPUT_IDS.OFFICE_ID] = officeCanBeDirectAssigned
        ? officeId || null
        : null;
      this.getValuationTypes();
      this.getUnderwriters(this.bankId);
    },

    setBankId(id) {
      this.bankId = id;
    },

    resetOffice() {
      this.creationFormStep = 2;
      this.data[FORM_INPUT_IDS.COMPANY_ID] = "";
      this.data[FORM_INPUT_IDS.OFFICE_ID] = "";
      this.resetErrors();
    },

    setSelectedOption(office, type) {
      this.selectedOffice = office;
      this.selectedOptionType = type;
    },

    setOffices(newOffices) {
      this.offices = newOffices;
    },

    async getOffices(bankId) {
      this.setOffices(null);
      
      try {
        const newOffices = await CompanyService.getCompanyOfficesByLocation({
          location: this.position.location,
          radius: 999999,
          bankId: bankId
        });
        this.setOffices(newOffices);
      } catch (e) {
        this.officesErrorMessage = ERROR_LOADING_MESSAGES.OFFICES;
      }
    },

    async getValuationTypes() {
      ValuationService.getTypes()
        .then(data => {
          this.types = data;
        })
        .catch(err => {
          this.typesErrorMessage = err.message;
        });
    },

    async getUnderwriters(bankId) {
      try {
        const response = await ValuationService.getUnderwriters(bankId);

        if (response.data.bankUsers.length) {
          this.underwriters = response.data.bankUsers.map(item => ({
            value: item?.id,
            label: `${item?.firstName} ${item?.lastName}`
          }));
        }
      } catch (err) {
        this.typesErrorMessage = err.message;
      }
    },

    async createValuation(isDraft) {
      try {
        this.setLoading(true);
        return await ValuationService.createValuation(
          this.data,
          this.locationData,
          isDraft
        );
      } catch (err) {
        this.setLoading(false);
        this.typesErrorMessage = err.message;
      }
    },

    async updateValuation() {
      try {
        this.setLoading(true);
        return await ValuationService.updateValuation(this.data);
      } catch (err) {
        this.setLoading(false);
        this.typesErrorMessage = err.message;
      }
    },

    async updateValuationStatus({ id, status }) {
      return await ValuationService.updateValuationStatus({ id, status });
    }
  }));
};

export default CreateValuationStore;
