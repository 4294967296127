import { destroyCookie } from "nookies";
import axios from 'axios';

import { VALUATION_REPORT_STATUS_ACTIONS, streetViewUrl } from "constants/index";
import ValuationService from "../service/valuation-service";
import BanksService from "../service/bank-service";

const FILESTACK_API_KEY = process.env.FILESTACK_API_KEY;

export const getIdFromUrl = () => {
  if (process.browser) {
    // Client-side-only code
    const url = window.location.href;
    return url.substring(url.lastIndexOf("/") + 1);
  }
};

export const safeParseJson = str => {
  try {
    const asString = JSON.parse(str);
    return asString;
  } catch (e) {
    return str;
  }
};

export const getFilteredOffices = (filters, offices) => {
  if (!filters?.length) return offices;

  return offices?.filter(item => {
    return (
      filters?.includes(item.organisationId) ||
      (item.organisationId === null && filters?.includes("independent"))
    );
  });
};

export function getEmptyFields(inputs) {
  let arr = Object.keys(inputs).map(key => {

    if(key === "NOTIFY_BROKER"){
      //todo hack to check if its a checkbox and set default value
      return [inputs[key], true];
    } else {
      return [inputs[key], ""];
    }
  });


  return Object.fromEntries(arr);
}

export const getFormattedOfficeData = data => {
  return {
    officeId: data.officeId,
    officeName: data.officeName,
    officeEmail: data.officeEmail,
    officePhone: data.officePhone,
    officeLatitude: data.officeLatitude,
    officeLongitude: data.officeLongitude,
    organisationName: data.organisationName,
    organisationId: data.organisationId,
    companyId: data.companyId,
    companyName: data.companyName,
    distance: data.distance || null
  };
};

/** It will return if the role and the valuation status provided, the action is allowed or not.
 * @param {string} role  userRole, get it from userInfo context
 * @param {string} valuationStatus the status of the valuation .
 * @param {('VIEW'|'REQUEST_CHANGES'|'MODIFY'| 'UPDATE_CHANGES')} action VIEW, REQUEST_CHANGES, MODIFY, UPDATE_CHANGES
 * @returns {boolean} isAllowed
 */
export const isValuationReportActionAllowed = (
  role,
  valuationStatus,
  action
) => {
  // Get the options for the requested action and user role.
  const actionOptions = (VALUATION_REPORT_STATUS_ACTIONS[role] ||
    VALUATION_REPORT_STATUS_ACTIONS["DEFAULT"])[action];

  if (actionOptions === "ALL") {
    return true;
  }
  return actionOptions.includes(valuationStatus);
};

// Example of cognito cookies, this function will run through all of them and remove the cookie.
// {
//   "CognitoIdentityServiceProvider.3arlbimd0clnp7lm30oc8fmarh.bank_admin.idToken":
//     "eyJraWQiOiJuR05MTzFIRzViUzNPWUVFK1FsUVgzK1hOd1psbzJYWWpkeWZQQ1NCNERFPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJlNzQ4MWEzNi01ZTZkLTQ5OWUtYWZmNy1mMDkwMjI2YzRhNjIiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LXdlc3QtMS5hbWF6b25hd3MuY29tXC9ldS13ZXN0LTFfcHRTZHlvTHNzIiwicGhvbmVfbnVtYmVyX3ZlcmlmaWVkIjp0cnVlLCJjb2duaXRvOnVzZXJuYW1lIjoiYmFua19hZG1pbiIsIm9yaWdpbl9qdGkiOiI3MmE3N2M1MS1iYTM4LTRhOTEtYmJjMi1iOTkwYjc3NTcxZDgiLCJhdWQiOiIzYXJsYmltZDBjbG5wN2xtMzBvYzhmbWFyaCIsImV2ZW50X2lkIjoiZDZlOWY5OTctMzU0Yi00NjRmLWJjOGItNDQ1YzdkZTNiZDM3IiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2NDM2MTM0NjMsInBob25lX251bWJlciI6IiszNTM4NTc1OTIyODIiLCJleHAiOjE2NDM2MTcwNjMsImlhdCI6MTY0MzYxMzQ2MywianRpIjoiM2Y3ZjUwODItZGI1Zi00NTcxLTg5Y2UtZWUxYzdiZTczNTg1IiwiZW1haWwiOiJkYW4rYmFua0BtYXdsYS5pZSJ9.B5iGEywD3awxdnCOPOizVl9tUwXcxjeiyk37AlaZVPLPUoAad3ctQSSD8HX_PZ4KlbIxdo32nN77B4Wfo_yIAlH2KTrOOqDRptrnJxYijAKKZVtolAFDzKCY1GmDu1-bznGCLLMgYwRRooiFivSetofeTmbomC8YoRkTQlSQh2MoV-n6Os_S-TpVcquWL8DNlxWJj3A3OgwYKPrSu-VjieRm17OWt1nOC3HQj4moW4vDaCFoF5SgyUbzHlpy-bFGTkxrLfaL_Tl2jtmwm0_KaC0_JsgfKFmgYNtzUH_pmXhJ-Lzf4MWDKg6DO1GT2yWWDuH217cR-OUyYjk78LhCkw",
//   "CognitoIdentityServiceProvider.3arlbimd0clnp7lm30oc8fmarh.bank_admin.accessToken":
//     "eyJraWQiOiJVRlwvMFlPN0dFOG9ZMytWemM3Zm9aYmhHekREZHNzbVlDOW5LbDJtc2dzYz0iLCJhbGciOiJSUzI1NiJ9.eyJvcmlnaW5fanRpIjoiNzJhNzdjNTEtYmEzOC00YTkxLWJiYzItYjk5MGI3NzU3MWQ4Iiwic3ViIjoiZTc0ODFhMzYtNWU2ZC00OTllLWFmZjctZjA5MDIyNmM0YTYyIiwiZXZlbnRfaWQiOiJkNmU5Zjk5Ny0zNTRiLTQ2NGYtYmM4Yi00NDVjN2RlM2JkMzciLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6ImF3cy5jb2duaXRvLnNpZ25pbi51c2VyLmFkbWluIiwiYXV0aF90aW1lIjoxNjQzNjEzNDYzLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuZXUtd2VzdC0xLmFtYXpvbmF3cy5jb21cL2V1LXdlc3QtMV9wdFNkeW9Mc3MiLCJleHAiOjE2NDM2MTcwNjMsImlhdCI6MTY0MzYxMzQ2MywianRpIjoiOGJmYTA3YWMtYzg3Yi00ZDExLTg0NzYtNWM5YmY5Y2I2YjU5IiwiY2xpZW50X2lkIjoiM2FybGJpbWQwY2xucDdsbTMwb2M4Zm1hcmgiLCJ1c2VybmFtZSI6ImJhbmtfYWRtaW4ifQ.uevScJ5qEUL1mbYD9abpT9RbCo_6vjPAgxKX8YNY8Vfg6I-hBAgKfOyHgi5FLCEPsrjJhrvSY4_Zjzkilbt0lNvttOR1JIlg76yAYr8QkH3gVAyAKE-FEh0Mx-4kBld-TsKoiDESioSNzQmKr3vgBWToz5H-01YdGm_2_qJXY4Cr4sfPyOqRnbklDlcQz9n8m6V1FPYqgzyUHiJfIYLKTcFVkdrSyc-A7HCT02o61S_IP0M0S0oFgWkBpHF-j06m9VbmiQdTBTDXIGT1Pzbz_aOilLIkUtmjLOLYwHW9m14wT2FS7oCmXKH_RTgNmmBJjX3D6nSVoLWbMJIdABinWg",
//   "CognitoIdentityServiceProvider.3arlbimd0clnp7lm30oc8fmarh.bank_admin.refreshToken":
//     "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.2Kwu-nT0E46avjvIA3gEz-o7sJhDnwrArUfUKP_Az679DivrYrfrlPRHGY0Z9zAG-BneQPsRHHz1oN2zSIbwpPKM2sl9Bl_EoxnMv_7NZmHPoHIX8GxUsKRWofIkc_CDrx98hbC2hedztWXMeUGBUyCmZmXcpedSVOkPcXUG8qZKMVfCbsWMN2bEuU4J8tJgtTrZjfSQRB8iDiAD4kNSejj3UcWxnK9f47a6sXpkKKDvqMdQ_T4KlEKEF9YMtGeXQISi6xIwciUBMJvAG_1_0DhMzAtULHwMVUMRfeZRVhZBsc2H-CKINg80dV_Aqt2snr2L_5bKwhtFovna8KQejw.wBOcNblKNIIix0Co.XqJRH4iOCDf2qAMWiYVTehPs5k-XNXrFr4gKWXRoheaFFIUfVkVPZRXgB0TgZua9svdYnVCYhchPsvK8qv8Z00QgUWjeeBSVIqo6JbV8ZPtF2XPIFe4aFtPjGNdJzXpbnWpKWnf45JTc_mxNIr_tex8gdq2lPBp7lD18VAMnQlhJAD6oFILuaExDEpzdhQOxLraSQ3BvHSpxV4Pcg9QGiiaLnI8oKOrSa1_JR7aC8L6Os3VBDMagXhB8fOEfEcfvfp56TSlJ0w0gDysn4BGCf98X8ce146lzxek_3kS6WZ3WHHScMU4Il7HQ7CV1Vq-704cQXsZtA1GUYdGD-U1v8pWnq8p34pPKUr_7gGbfrNoe311rjXuTCVYe2_q40vX7zGWl7lqTg5xX0xDNi5thYBdKRTfPMIoJ0ThhKdpd0NVQRXNXFFfKTDaYJoKKgWa6N6_whdfQgc_ba_wHscJeE_Y9-gaa_rXge0BVRwwmKDZowZhBDKCi10q0aj9wZHzJnzqb7QUdNQ6iUtxeeNUhTRAozW4i8-3zFtwjdYQnLvIbN7drrKDLmhSeSq5nhq146oOtweV2V9FkSh2t_cyGzSnqUL7NZzFEuL7jAHn6zuhl-8z0q6jfkC8XLOIe-N9aNWvqNjqLExJ5860dbdietACYSX38i2LCg8QpjfHQFL3JoZ9gGCAXIKUGbRJT-lJh_fQ1qNmh-k6QCROLou2b45IkJYjEqqxsce9_1XCZk6FTzs4TyV5E4YFB6rKaygcVu7VRAdygbHWWjAIwRHWGOlbtRuiN_sZB7apWY-73V-BhxS8Q5hJO0Y7lrdU_Qe5moquWNYx5gvOJSbpzizPw-IuUNmj7bzro5GwPms_dGdAVErQAxaqBfzUnia9SDP7HdCc581Azpezuelbi25eTAsAdG9iiW9yDn2ykUvzA1Mmq6mPRUOQr7V7xUUYXVYxT3rh8KbHJGN13NfhMF0aTpbhVxEerOVzcgaWFg5xUf_sXBuQfZht--ZgsSzgbfgUhw_ywSsYhTTLaHKqncvx1Hbnsaxh0kWnZEKZ9QzZbDtvoMhWoQ9Ef2OuDFWPo4DTOYhc2RRudV3L9k7tF0wyHbB_MozGJtHx7628rQO2OCXTp5JK8KkvaH9ZB2UEn22g3xVE8NqnAu6ITnF4xIWvlcqbkha0NewnW-RaT3bmfpNZ_HlpcC9iKqxo-y-f37TT8vO4a8z5kfsqe9WKR9Jq5x2384FYU4a_8a5o3Z5H6-JEmUMtNg96F8tgqH8mqIAlK_CCgQE69Cl8YYw.xNxBFsPXeZ_Ow61iO6b6mA",
//   "CognitoIdentityServiceProvider.3arlbimd0clnp7lm30oc8fmarh.LastAuthUser":
//     "bank_admin"
// }

export const destroyCognitoCookies = cookies => {
  const COGNITO_COOKIES_KEY = "CognitoIdentityServiceProvider";
  if (!cookies) return;
  Object.keys(cookies).forEach(cookie => {
    if (cookie.includes(COGNITO_COOKIES_KEY)) {
      destroyCookie(null, cookie);
    }
  });
};

export const transformFormTemplate = formTemplate => (
  formTemplate.map(panel => {
    if (panel.name === "Valuer Sign Off") {
      const fields = [
        {
          fieldType: "TEXT",
          html: "<div><span class='text-accent-blue'>NOTE: </span> For the purpose of this valuation “Market Value” is defined as ‘the estimated amount for which an asset or liability should exchange on the valuation date between a willing buyer and a willing seller in an arm’s length transaction, after proper marketing and where the parties had each acted knowledgeably, prudently and without compulsion. I certify that the property described in this report has been inspected by me on the date of the inspection specified below, that I have the knowledge, skills, and understanding to undertake the valuation competently, that I have had full access to the property and that I have valued the property and prepared the report in accordance with International Valuation Standards and the RICS ‘Red Book’ or the TEGoVA ‘Blue Book’.</div>",
          fieldUI: null,
          options: null,
          placeholder: null,
          group: null,
          defaultCheckedIndex: null,
          defaultValue: null,
          label: null,
          meta: null,
          icon: null,
          subPanel: "text",
          name: null,
          validation: {
            validationType: "STRING",
            parameters: [
              {
                type: "required",
                value: ["this field is required"]
              }
            ]
          }
        },
        {
          fieldType: "TEXT",
          html: "<div>I certify that neither I, nor my employees, have a conflict of interest (as outlined in the terms of appointment to the lender’s valuation panel) in respect of this property. I certify that this firm holds up to date Professional Indemnity Insurance for a minimum of €1,000,000. A current photograph of the property is enclosed. This valuation report is addressed to the Lender, which proposes to provide the above named applicants with a mortgage loan secured on the above property, namely to Finance Ireland Credit Solutions DAC, trading as Finance Ireland Residential Mortgages, Finance Ireland Agri, Finance Ireland Leasing, Finance Ireland Commercial Mortgages and Finance Ireland.</div>",
          fieldUI: null,
          options: null,
          placeholder: null,
          group: null,
          defaultCheckedIndex: null,
          defaultValue: null,
          label: null,
          meta: null,
          icon: null,
          subPanel: "text",
          name: null,
          validation: {
            validationType: "STRING",
            parameters: [
              {
                type: "required",
                value: ["this field is required"]
              }
            ]
          }
        },
        {
          fieldType: "INPUT",
          html: null,
          fieldUI: "name-of-valuer",
          options: null,
          placeholder: null,
          group: null,
          defaultCheckedIndex: null,
          defaultValue: null,
          label: "Name of Valuer",
          meta: '{"className":"mb-4", "isMediumFont":true}',
          icon: null,
          subPanel: "fields",
          name: null,
          validation: {
            validationType: "STRING",
            parameters: [
              {
                type: "required",
                value: ["this field is required"]
              }
            ]
          }
        },
        {
          fieldType: "SIGNATURE",
          html: null,
          fieldUI: "signature-of-valuer",
          options: null,
          placeholder: null,
          group: null,
          defaultCheckedIndex: null,
          defaultValue: null,
          label: "Signature of Valuer",
          meta: '{"className":"mb-4", "isMediumFont":true}',
          icon: null,
          subPanel: "fields",
          name: null,
          validation: {
            validationType: "STRING",
            parameters: [
              {
                type: "required",
                value: ["this field is required"]
              }
            ]
          }
        },
        {
          fieldType: "INPUT",
          html: null,
          fieldUI: "qualification-of-valuer",
          options: null,
          placeholder: null,
          group: null,
          defaultCheckedIndex: null,
          defaultValue: null,
          label: "Qualification of Valuer",
          meta: '{"className":"mb-4", "isMediumFont":true}',
          icon: null,
          subPanel: "fields",
          name: null,
          validation: {
            validationType: "STRING",
            parameters: [
              {
                type: "required",
                value: ["this field is required"]
              }
            ]
          }
        },
        {
          fieldType: "INPUT",
          html: null,
          fieldUI: "company-name-address",
          options: null,
          placeholder: null,
          group: null,
          defaultCheckedIndex: null,
          defaultValue: null,
          label: "Company Name & Address",
          meta: '{"className":"mb-4", "isMediumFont":true}',
          icon: null,
          subPanel: "fields",
          name: null,
          validation: {
            validationType: "STRING",
            parameters: [
              {
                type: "required",
                value: ["this field is required"]
              }
            ]
          }
        },
        {
          fieldType: "DATE_PICKER",
          html: null,
          fieldUI: "date-of-report",
          options: null,
          placeholder: null,
          group: null,
          defaultCheckedIndex: null,
          defaultValue: null,
          label: "Date of Report DD/MM/YYYY",
          meta: '{"className":"mb-4", "isMediumFont":true}',
          icon: null,
          subPanel: "fields",
          name: null,
          validation: {
            validationType: "STRING",
            parameters: [
              {
                type: "required",
                value: ["this field is required"]
              }
            ]
          }
        },
        {
          fieldType: "CERTIFICATE",
          html: null,
          fieldUI: null,
          options: null,
          placeholder: null,
          group: null,
          defaultCheckedIndex: null,
          defaultValue: null,
          label: null,
          meta: null,
          icon: null,
          subPanel: "fields",
          name: null,
          validation: {
            validationType: "STRING",
            parameters: [
              {
                type: "required",
                value: ["this field is required"]
              }
            ]
          }
        },
        {
          fieldType: "RADIO_GROUP",
          html: null,
          fieldUI: "is-final-inspection-required",
          options: ["Yes", "No"],
          placeholder: null,
          group: null,
          defaultCheckedIndex: null,
          defaultValue: null,
          label: "Is a Final Inspection & Report Required",
          meta: '{"className":"mb-4", "isMediumFont":true}',
          icon: null,
          subPanel: "fields",
          name: null,
          validation: {
            validationType: "STRING",
            parameters: [
              {
                type: "required",
                value: ["this field is required"]
              }
            ]
          }
        },
        {
          fieldType: "TEXT_AREA",
          html: null,
          fieldUI: "additional_location",
          options: null,
          placeholder: null,
          group: null,
          defaultCheckedIndex: null,
          defaultValue: null,
          label: "Additional Location Comments (if relevant)",
          meta: '{"className":"mb-4", "isMediumFont":true}',
          icon: null,
          subPanel: "fields",
          name: null,
          validation: {
            validationType: "STRING",
            parameters: [
              {
                type: "required",
                value: ["this field is required"]
              }
            ]
          }
        },
      ];
      return { ...panel, fields };
    }

    return panel;
  })
)

export const getValuationRequestValues = valuation => {
  const valuationReportValues = {
    valuer: "name-of-valuer",
    securityAddress: "address",
    sellingAgent: "sales-agent",
    company: "company-name-address",
    valuationType: "valuation-type",
    customerName: "name-of-applicant",
    expectedMarketValue: "market-value",
    propertyCaseNumber: "mortgage-ref-number",
    eircode: "eir-code"
  };

  const valuationTypes = {
    FULL: "Full",
    FINAL: "Final",
    // TODO: confirm which type is correct for valuation request data
    SUBSEQUENT: "Subsequent",
    REVALUATION: "Subsequent"
  };

  return Object.keys(valuation).reduce((acc, key) => {
    if (valuation[key] && valuationReportValues[key]) {
      switch (key) {
        case "valuer":
          acc[
            valuationReportValues[key]
            ] = `${valuation[key].firstName} ${valuation[key].lastName}`;
          break;
        case "company":
          acc[valuationReportValues[key]] = valuation[key].name;
          break;
        case "valuationType":
          acc[valuationReportValues[key]] = valuationTypes[valuation[key]];
          break;
        case "expectedMarketValue":
          acc[valuationReportValues[key]] = `€${valuation[key]}`;
          break;
        default:
          acc[valuationReportValues[key]] = valuation[key];
      }
    }
    return acc;
  }, {});
};

export const getReviewRequestedSteps = (form, fieldsForReview) => (
  form.reduce((acc, { fields }, index) => {
    fields.forEach(({ fieldUI }) => {
      if (fieldUI && fieldsForReview[fieldUI]) {
        acc.push(index + 1);
      }
    });

    return acc;
  }, [])
);

export const numberToPrice = (value) => {
  if (!value.trim()) return "";

  return value
    .replace(/\D/g, "")
    .split("")
    .reverse()
    .map((char, index) => {
      if (index && index % 3 === 0) return `${char},`;

      return char;
    }).reverse()
    .join("");
};

export const swapPropertyImage = async (valuation) => {
  const imageUrl = streetViewUrl(valuation);
  try {
    await axios.get(imageUrl, { responseType: 'arraybuffer' })
      .then((response) => {
        const { data } = response;
       
        return data;
      })
      .then(async (image) => {
        await axios.post(`https://www.filestackapi.com/api/store/S3?key=${FILESTACK_API_KEY}`, image, {
          headers: {
            'Content-Type': 'image/jpg',
          },
        }).then(async (response) => {
          const { data: { url } } = response;
          const { id } = valuation;

          await ValuationService.updateValuation({
            ...valuation,
            valuationId: id,
            propertyImageLinkUrl: url,
          });
        })
      });
  } catch (e) {
    console.warn(e);
  }
};

export const getValuationsToDisplay = (allValuations, userQueries) => {
  const { organisationIds: selectedOrganizations, companyIds: selectedCompanies } = userQueries;

  if (!selectedOrganizations.length && !selectedCompanies.length) {
    return allValuations;
  }

  if (selectedOrganizations.length && !selectedCompanies.length) {
    return allValuations.filter((valuation) => selectedOrganizations.includes(valuation.organisation?.id));
  }

  if (!selectedOrganizations.length && selectedCompanies.length) {
    return allValuations.filter((valuation) => selectedCompanies.includes(valuation.company?.id));
  }

  const filteredByOrganizations = allValuations.filter((valuation) => selectedOrganizations.includes(valuation.organisation?.id));
  const filteredByCompanies = allValuations.filter((valuation) => selectedCompanies.includes(valuation.company?.id));

  return [...filteredByOrganizations,...filteredByCompanies];
};

export const generateTableForExport = (data, tableRef) => {
  const tableBody = data.map((valuation) => {
    const {
        propertyCaseNumber,
        propertyAddress,
        companyName,
        valuationAmount,
        organizationsName,
        valuationUrl,
        valuer,
        approvalDate,
        status,
        lastStatusChange,
        pdfReportUrl,
      } = valuation;

      return (
        <tr key={valuationUrl}>
          <td>{propertyCaseNumber}</td>
          <td>{propertyAddress}</td>
          <td>{valuer}</td>
          <td>{companyName}</td>
          <td>{organizationsName}</td>
          <td>{status}</td>
          <td>{lastStatusChange.split("-").reverse().join("-")}</td>
          <td>{approvalDate.split("-").reverse().join("-")}</td>
          <td>{valuationAmount}</td>
          <td>{valuationUrl}</td>
          <td>{pdfReportUrl}</td>
        </tr>
      );
    });

  return (
    <table ref={tableRef}>
      <thead>
        <tr>
          <td>Property Case Number</td>
          <td>Security Adress</td>
          <td>Valuer Name</td>
          <td>Company Name</td>
          <td>Organization Name</td>
          <td>Valuation Status</td>
          <td>Last Status Change</td>
          <td>Approval Date</td>
          <td>Property Valuation Amount</td>
          <td>Valuation URL</td>
          <td>Download report (PDF)</td>
        </tr>
      </thead>
      <tbody>
        {tableBody}
      </tbody>
    </table>
  );
};

export const isUrl = (data) => {
  const url = new URL(data);
  
  return url.protocol === "https:" || url.protocol === "http:";
};

export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(','),
  mime = arr[0].match(/:(.*?);/)[1],
  bstr = atob(arr[1]), 
  n = bstr.length, 
  u8arr = new Uint8Array(n);
        
  while(n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
    
  return new File([u8arr], filename, {type:mime});
};

export const swapSignature = async (file, formValuesId, valuationId, formValues, fieldsForReview, setFormValuesRequestError) => {
  try {
    await axios.post(`https://www.filestackapi.com/api/store/S3?key=${FILESTACK_API_KEY}`, file, {
      headers: {
          'Content-Type': 'image/jpg',
        },
      }).then(async (response) => {
        const { data: { url } } = response;
  
        await BanksService.saveValuationForm({
          id: formValuesId,
          valuationId: valuationId,
          values: { ...formValues, "signature-of-valuer": url },
          revalidation: fieldsForReview,
        });
      });
  } catch (error) {
    console.warn(error);
    setFormValuesRequestError(error.message);
  }
};
