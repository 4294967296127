import { SignIn } from 'aws-amplify-react';
import { LoginLayout } from './components/LoginLayout';
import PasswordField from './components/PasswordField';
import Input from "components/Input/Input";
import Checkbox from "components/Checkbox/Checkbox";
import Button from "components/Button/Button";

class MySignIn extends SignIn {
  state = {
    error: '',
    isPasswordEdted: false,
    isUsernameEdited: false,
  }

  errorMessage = (err) => {
    this.setState(() => ({ error: err.message }));
  }

  forgotPasswordClick = () => {
    this.changeState('forgotPassword')
  }

  setNativeValue(element, value) {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set
    const prototype = Object.getPrototypeOf(element)
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(
      prototype,
      'value'
    ).set

    if (valueSetter && valueSetter !== prototypeValueSetter) {
      prototypeValueSetter.call(element, value)
    } else {
      valueSetter.call(element, value)
    }
  }

  getDecryptedPass = async () => {
    try {
      const arr = window?.location.href.split('token=');
      if (!arr || !arr[1]) return;
      let content = arr[1];
      const res = await fetch(`/api/decrypt`, {
        method: "POST",
        body: JSON.stringify({ code: content })
      })
      const body = await res.json();
      const { code } = body
      const encrypted = code;
      
      const user = JSON.parse(encrypted)
      if (!user) return
      user.password = user.password.replace('&amp;', '&')
  
      const e = new Event('input', { bubbles: true })
      if (!this.state.isPasswordEdted && content !== '') {
        const input = document.querySelector('[name="password"]')
        const usernameInput = document.querySelector('[name="username"]')
        if (usernameInput) {
          this.setNativeValue(usernameInput, '')
          usernameInput.dispatchEvent(e)
          this.setNativeValue(usernameInput, user.username)
          usernameInput.dispatchEvent(e)
          this.setState({
            isUsernameEdited: true
          });
        }
        if (input) {
          this.setNativeValue(input, '')
          input.dispatchEvent(e)
          this.setNativeValue(input, user.password)
          input.dispatchEvent(e)
          this.setState({
            isPasswordEdted: true
          });
        }
      } 
    } catch (e) {
      console.warn(e);
      return;
    }
  }

  componentDidMount() {
    this.getDecryptedPass()
  }

  handleAuth = async (ev) => {
    ev.preventDefault();
    this.signIn(ev)
  }

  onInputChange = (event) => {
    if (event.target.name === 'password' && !this.state.isPasswordEdted) {
      this.setState(() => ({ error: '', isPasswordEdted: true }))
    } else if (event.target.name === 'username' && !this.state.isUsernameEdited) {
      this.setState(() => ({ error: '', isUsernameEdited: true }));
    } else {
      this.setState(() => ({ error: '' }));
    }
    this.handleInputChange(event)
  }

  render() {
    if (this.props.authState !== "signIn") return null

    return (
      <LoginLayout title={"Log in to Your Account"} errorMessage={this.state.error}>
        <form className="relative space-y-6 py-6 pt-4 px-8" onSubmit={this.handleAuth}>
          <Input
            label="Email Address"
            isMediumFont={true}
            id="username"
            name="username"
            required={true}
            onChange={this.onInputChange}
            autoComplete="off"
          />
          <PasswordField
            onChange={(ev) => this.onInputChange(ev)}
            id="password"
            name="password"
            label="Password"
          />
          <div className="flex items-center justify-between">
            <Checkbox name="remember_me" id="remember_me" label="Remember me" />
            <div className="text-sm leading-5">
              <button
                type='button'
                className="text-base no-underline text-accent-blue visited:text-accent-blue hover:text-indigo-600 focus:outline-none focus:underline transition ease-in-out duration-150"
                onClick={this.forgotPasswordClick}
              >
                Forgot password?
              </button>
            </div>
          </div>
          <Button
            type="submit"
            variant="primary"
            size="normal"
            isFullWidth={true}
            isTextCenter={true}
            loading={false}
          >
            Log in
          </Button>
        </form>
      </LoginLayout>
    )
  }
};

export default MySignIn;
