import cx from 'classnames';

export const LoginLayout = ({ title, errorMessage, children }) => {
  const convertError = (err) => {
    const num = err.split("validation error").length
    const mess = num > 1 ? "Username can't contain whitespaces" : err 
    return mess;
  }

  return (
    <div
      className="min-h-screen flex justify-center items-center bg-gray-5 px-2 py-4"
    >
    <div className='min-w-350 '>
      <div className="flex justify-center mb-8">
          <img src='/image/cv_logo.svg' />
      </div>
      <h2 className="text-3xl mb-8 leading-9 font-extrabold text-gray-900 text-center">
        {title}
      </h2>
      <div className="relative bg-white rounded-md" style={{ boxShadow: '0px 35px 40px rgba(0, 0, 0, 0.15)'}}>
        <div className="w-full max-w-sm lg:w-96">
          <div className={cx('text-red-500 px-8 pt-4')}>
            <p>{convertError(errorMessage)}</p>
          </div>
          {children}
        </div>
      </div>
    </div>
  </div>
  )
}