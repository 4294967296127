// TODO: Remove this file when the connection with BE is completed.
export const fake_valuation_form_response = {
  data: {
    valuationForm: {
      id: 1,
      content: '{"panel": "mawla-abc"}',
      active: 1,
      bankId: 1,
      panels: [
        {
          name: "Notes and recommendations",
          panelBreak: 1,
          fieldsGroups: null,
          fields: [
            {
              fieldType: "TEXT",
              html: "\n<div> <span class='text-accent-blue'>NOTES AND RECOMMENDATIONS FOR APPLICANT(s):</span>\n              This document is a report from the Lender’s Valuer to the Lender, providing a report on the Market Value and the Condition of the property <strong> FOR MORTGAGE PURPOSES ONLY. </strong>\n              This report is made for the sole benefit of the Lender, it remains the property of the Lender and cannot be relied upon by you the Applicant or any other party. The valuation figure does not warrant that the purchase price is reasonable.<br/>\n              It is stressed that this report is based on a limited inspection carried out for mortgage purposes only. It is <strong>NOT A BUILDING OR STRUCTURAL SURVEY.</strong> There may be defects in the property the existence or extent of which could only be revealed by a detailed inspection. In a limited inspection for mortgage purposes it is also possible that there are other defects that may not have been recorded as significant in this type of  valuation.</div>",
              fieldUI: null,
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: null,
              meta: null,
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "TEXT",
              html: "\n<div> <strong>YOU SHOULD NOT ASSUME</strong> that the defects shown (if any) are the only ones present in the property, or if no repairs are mentioned, that no defects exist. No responsibility whatsoever is implied or accepted or warranty given by the Lender for the value or condition of the property by reason of this valuation. <br/>\n                You are strongly recommended to arrange for an independent valuation and a complete Building Survey (including a survey of the structure) to\n                be undertaken on your behalf before signing a Contract to purchase. In respect of the survey you should consult a Chartered/Registered Building Surveyor, Registered Architect or Engineer to complete such report on your behalf. You may also wish to consider whether you need to obtain specialist reports (e.g. Pyrite, ‘Dry Rot’ or ‘Subsidence’) as part of any such building survey.</div>",
              fieldUI: null,
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: null,
              meta: null,
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            }
          ],
          subPanels: null
        },
        {
          name: "Summary",
          panelBreak: 4,
          fieldsGroups: null,
          fields: [
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "mortgage-ref-number",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: "Mortage number",
              label: "Mortgage Reference Number",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,

              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  },
                  {
                    type: "min",
                    value: [5, "name cannot be less than 5 characters"]
                  },
                  {
                    type: "max",
                    value: [16, "name cannot be more than 16 characters"]
                  }
                ]
              }
            },
            {
              fieldType: "TEXT_AREA",
              html: null,
              fieldUI: "name-of-applicant",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Name of applicant(s)",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              fieldSubType: "price",
              html: null,
              fieldUI: "market-value",
              options: null,
              placeholder: "€",
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Market value",
              meta: '{"className":"mb-4 mr-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              fieldSubType: "price",
              html: null,
              fieldUI: "purchase-price",
              options: null,
              placeholder: "€",
              group: null,
              defaultCheckedIndex: null,
              NAOption: true,
              defaultValue: null,
              label: "Purchase price (if relevant)",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "valuation-type",
              options: ["Full", "Final", "Subsequent"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: "Full",
              label: "Valuation type",
              meta: '{"className":"mb-4", "isMediumFont":true,  "fullWidth": true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "DATE_PICKER",
              html: null,
              fieldUI: "valuation-date",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Valuation Date",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: "/image/calendar-icon.svg",
              subPanel: null,
              name: null,
              unsetDefaultValue: true,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "type-of-transaction",
              options: [
                "Open sale market",
                "Equity release",
                "Off market sale",
                "Other"
              ],
              placeholder: null,
              group: null,
              defaultCheckedIndex: 0,
              defaultValue: null,
              label: "Type of transaction",
              meta: '{"className":"mb-4", "isMediumFont":true, "marginBottom": 4,  "fullWidth": true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "sales-agent",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Sales Agent (if relevant)",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "conditional",
                    conditionFieldUI: "type-of-transaction",
                    conditionValue: ["Open sale market", "Off market sale"],
                    value: ["this field is required"]
                  }
                ]
              }
            }
          ],
          subPanels: null
        },
        {
          name: "Location",
          panelBreak: 3,
          fieldsGroups: [
            {
              fieldGroupUI: "post",
              meta: '{"className": "flex justify-between mb-4"}'
            },
            {
              fieldGroupUI: "county",
              meta: '{"className": "flex justify-between mb-4"}'
            }
          ],
          fields: [
            {
              fieldType: "TEXT_AREA",
              html: null,
              fieldUI: "address",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Address",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "0post-code",
              options: null,
              placeholder: null,
              group: "post",
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Post code (if Dublin)",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              NAOption: true,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "eir-code",
              options: null,
              placeholder: null,
              group: "post",
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Eircode",
              meta: '{"className":"mb-4 ml-4","isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              NAOption: true,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "county",
              options: null,
              placeholder: null,
              group: "county",
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "County",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "type",
              options: ["Urban", "Rural", "Suburban"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: "Urban",
              label: "Location Type",
              meta: '{"className":"mb-4", "isMediumFont":true,  "fullWidth": true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "access-to-public-road",
              options: ["Yes", "No"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: 0,
              defaultValue: null,
              label:
                "Does the property have access to or frontage onto a public road?",
              meta: '{"className":"mb-4", "isMediumFont":true, "marginRight": 4}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "TEXT_AREA",
              html: null,
              fieldUI: "additional-information",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Additional Location Comments (if relevant)",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: []
              }
            }
          ],
          subPanels: null
        },
        {
          name: "Description",
          panelBreak: 5,
          subPanels: [
            {
              subPanelUI: "property_type",
              meta: "",
              subPanelBreak: null
            },
            {
              subPanelUI: "exterior",
              meta: '{"formLayoutClassName": "flex justify-between"}',
              subPanelBreak: 0
            },
            {
              subPanelUI: "condition",
              meta: '{"formLayoutClassName": "flex justify-between"}',
              subPanelBreak: 0
            },
            {
              subPanelUI: "complete_status",
              meta: '{"formLayoutClassName": "flex justify-between"}',
              subPanelBreak: 0
            },
            {
              subPanelUI: "energy",
              meta: "",
              subPanelBreak: 2
            },
            {
              subPanelUI: "additional",
              meta: "",
              subPanelBreak: 5
            }
          ],
          fieldsGroups: [
            {
              fieldGroupUI: "service",
              meta: '{"className": "flex justify-between mb-4 items-end", "wrapGroupInPDF": true}'
            },
            {
              fieldGroupUI: "area",
              meta: '{"className": "flex justify-between mb-4"}'
            },
            {
              fieldGroupUI: "completion",
              meta: '{"className": "flex flex-wrap justify-start mb-4 ml-8", "wrapGroupInPDF": true}'
            }
          ],
          fields: [
            {
              fieldType: "TEXT",
              html: "<strong>Property Type</strong>",
              fieldUI: null,
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: null,
              meta: null,
              icon: null,
              subPanel: "property_type",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "property-type-a",
              options: ["New build", "Second hand"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "a)",
              meta: '{"className":"mb-4", "isMediumFont":true,  "marginRight": 4}',
              icon: null,
              subPanel: "property_type",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "property-type-b",
              options: [
                "Detached",
                "Semi-detached",
                "Mid-terrace",
                "End of terrace",
                "Cottage",
                "Duplex",
                "Apartment",
                "Penthouse",
                "Bungalow",
                "Dormer",
                "Other"
              ],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "b)",
              meta: '{"className":"mb-4",   "marginBottom": 4,  "flexStart": true}',
              icon: null,
              subPanel: "property_type",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              fieldSubType: "price",
              html: null,
              fieldUI: "annual-service-charge",
              options: null,
              placeholder: "€",
              group: "service",
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Annual Service Charge (if applicable)",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "property_type",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "conditional",
                    conditionFieldUI: "property-type-b",
                    conditionValue: [
                      "Detached",
                      "Semi-detached",
                      "Mid-terrace",
                      "End of terrace",
                      "Cottage",
                      "Duplex",
                      "Apartment",
                      "Penthouse",
                      "Bungalow",
                      "Dormer",
                      "Other"
                    ],
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "NUMBER",
              html: null,
              fieldUI: "number-of-bedrooms",
              options: null,
              placeholder: null,
              group: "service",
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Number of Bedrooms",
              meta: '{"className":"mb-4  ml-4", "isMediumFont":true}',
              icon: null,
              subPanel: "property_type",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "NUMBER",
              html: null,
              fieldUI: "floor-area",
              options: null,
              placeholder: null,
              group: "area",
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Floor Area (m2)",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "property_type",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "NUMBER",
              html: null,
              fieldUI: "plot-size",
              options: null,
              placeholder: null,
              group: "area",
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Plot Size (Hectares)* Hectares not SQ meters",
              meta: '{"className":"mb-4  ml-4", "isMediumFont":true}',
              icon: null,
              subPanel: "property_type",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: []
              }
            },
            {
              fieldType: "TEXT",
              html: "<strong>If apartment:</strong>",
              fieldUI: null,
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: null,
              meta: null,
              icon: null,
              subPanel: "property_type",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "NUMBER",
              html: null,
              fieldUI: "no-of-floors-in-block",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "a) No of Floors in Block",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "property_type",
              name: null,
              NAOption: true,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "NUMBER",
              html: null,
              fieldUI: "apartment-floor",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "b) Apartment Floor (Location)",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "property_type",
              name: null,
              NAOption: true,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "balconies-terrace-area",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "c) Balconies/Terrace area",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "property_type",
              name: null,
              NAOption: true,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "any-extensions",
              options: ["Yes", "No"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Any extensions:",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "property_type",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: []
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "any-outbuildings",
              options: ["Yes", "No"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Any outbuildings:",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "property_type",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: []
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "garden",
              options: ["Front", "Back", "Both"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Garden",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "exterior",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: []
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "property-type",
              options: ["Drive-way", "Garage", "On street", "Communal"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Car parking",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "exterior",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: []
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "how-many-car",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "How many car spaces included in valuation:",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "exterior",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: []
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "approximate-year-of-completion",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Approximate year of completion",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "condition",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "external-condition",
              options: ["Excellent", "Good", "Fair", "Poor"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "External condition",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "condition",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "internal-condition",
              options: ["Excellent", "Good", "Fair", "Poor"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Internal condition",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "condition",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "is-the-property-complete",
              options: ["Yes", "No"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Is the property complete? (kitchen, bathroom, etc, ...)",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "complete_status",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "TEXT",
              html: "<strong>If 'No':</strong>",
              fieldUI: null,
              options: null,
              placeholder: null,
              group: "completion",
              defaultCheckedIndex: null,
              defaultValue: null,
              label: null,
              meta: '{"className":"w-full"}',
              icon: null,
              subPanel: "complete_status",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "DATE_PICKER",
              html: null,
              fieldUI: "estimated-completion-date",
              options: null,
              placeholder: "",
              group: "completion",
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "a) Estimated completion date",
              meta: '{"className":"mb-4 mr-4", "isMediumFont":true}',
              icon: null,
              subPanel: "complete_status",
              name: null,
              NAOption: true,
              unsetDefaultValue: true,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "conditional",
                    conditionFieldUI: "is-the-property-complete",
                    conditionValue: ["No"],
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              fieldSubType: "price",
              html: null,
              fieldUI: "value-in-current-condition",
              options: null,
              placeholder: "€",
              group: "completion",
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "b) Value in current condition",
              meta: '{"className":"mb-4 mr-4", "isMediumFont":true}',
              icon: null,
              subPanel: "complete_status",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "conditional",
                    conditionFieldUI: "is-the-property-complete",
                    conditionValue: ["No"],
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              fieldSubType: "price",
              html: null,
              fieldUI: "value-on-completion",
              options: null,
              placeholder: "€",
              group: "completion",
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "c) Value on completion",
              meta: '{"className":"mb-4 mr-4", "isMediumFont":true}',
              icon: null,
              subPanel: "complete_status",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "conditional",
                    conditionFieldUI: "is-the-property-complete",
                    conditionValue: ["No"],
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "central-heating-system",
              options: ["Oil", "Gas", "Solid Fuel", "Heat Pump", "Air Recovery System", "Other"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Central Heating System",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "energy",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "sewage-system",
              options: ["Mains", "Private Septic Tank"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Sewage System",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "energy",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "building-energy-rating",
              options: ["Yes", "No", "Not applicable"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Building Energy Rating",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "energy",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "if-yes-rating",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "If 'Yes', rating:",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "energy",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "conditional",
                    conditionFieldUI: "building-energy-rating",
                    conditionValue: ["Yes"],
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "any-visual",
              options: ["Yes", "No"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label:
                "Any visual evidence of Dry Rot, Subsisdence, Woodworm, Settlement or anything else negatively impacting value",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "additional",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "is-the-property-prone-to-flooding",
              options: ["Yes", "No"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Is the property prone to flooding?",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "additional",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "is-the-property-free-from-visual",
              options: ["Yes", "No"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Is the property free from visual signs of pyrite?",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "additional",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "is-a-building-surveyor",
              options: ["Yes", "No"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Is a Building Surveyor required?",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "additional",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "do-you-believe",
              options: ["Yes", "No"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label:
                "Do you believe any other specialist reports are required?",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "additional",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              fieldSubType: "price",
              html: null,
              fieldUI: "estimated-reinstatement-value",
              options: null,
              placeholder: "€",
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Estimated Reinstatement Value",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "additional",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "is-the-property-used-wholly",
              options: ["Yes", "No"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label:
                "Is the property to be used wholly as a private residence?",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "additional",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "TEXT_AREA",
              html: null,
              fieldUI: "additional-description-comments",
              options: null,
              placeholder: "€",
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Additional Description Comments (if relevant)",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "additional",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: []
              }
            }
          ]
        },
        {
          name: "Letting Potential",
          fieldsGroups: [
            {
              fieldGroupUI: "post",
              meta: '{"className": "flex justify-between mb-4"}'
            },
            {
              fieldGroupUI: "post",
              meta: '{"className": "flex justify-between mb-4"}'
            }
          ],
          panelBreak: 7,
          fields: [
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "property-use",
              options: ["Owner Occupied", "Investment"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Property Use",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "is-the-property-suitable",
              options: ["Yes", "No"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label:
                "Is the property suitable for letting in current condition?",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "TEXT_AREA",
              html: null,
              fieldUI: "letting-condition-details",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "If 'No', details:",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "conditional",
                    conditionFieldUI: "is-the-property-suitable",
                    conditionValue: ["No"],
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "level-of-demand",
              options: ["Good", "Fair", "Poor"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Level of demand in area",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "NUMBER",
              html: null,
              fieldUI: "estimated-time-to-let",
              options: null,
              placeholder: "months",
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Estimated time to let:",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "lease-in-place",
              options: ["yes", "no"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Is there a lease in place?",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "conditional",
                    conditionFieldUI: "property-use",
                    conditionValue: ["Investment"],
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "TEXT_AREA",
              html: null,
              fieldUI: "lease-details",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "If 'Yes', details:",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "conditional",
                    conditionFieldUI: "lease-in-place",
                    conditionValue: ["yes"],
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "current-rent",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              NAOption: true,
              label: "Current Rent (per month) if rented",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "conditional",
                    conditionFieldUI: "lease-in-place",
                    conditionValue: ["yes"],
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "market-rent",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Potential Market Rent/Value (per month) even if not rented",
              NAOption: false,
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "asset-located",
              options: ["Yes", "No"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Is the asset located in a rent pressure zone?",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "DATE_PICKER",
              html: null,
              fieldUI: "next-review-date",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "If Yes, date of next review DD/MM/YYYY",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              NAOption: true,
              unsetDefaultValue: true,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "conditional",
                    conditionFieldUI: "asset-located",
                    conditionValue: ["Yes"],
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              fieldSubType: "price",
              html: null,
              fieldUI: "next-review",
              options: null,
              placeholder: "€",
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              NAOption: true,
              label: "Rent at next review",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "conditional",
                    conditionFieldUI: "asset-located",
                    conditionValue: ["Yes"],
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "TEXT_AREA",
              html: null,
              fieldUI: "additional-valuer-comments",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Additional Valuer Comments (if relevant)",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: []
              }
            }
          ],
          subPanels: null
        },
        {
          name: "Valuation Rationale",
          fieldsGroups: [
            {
              fieldGroupUI: "post",
              meta: '{"className": "flex justify-between mb-4"}'
            },
            {
              fieldGroupUI: "post",
              meta: '{"className": "flex justify-between mb-4"}'
            }
          ],
          subPanels: [
            {
              subPanelUI: "market",
              meta: null,
              subPanelBreak: 2
            }
          ],
          panelBreak: 3,
          fields: [
            {
              fieldType: "INPUT",
              fieldSubType: "price",
              html: null,
              fieldUI: "market-value",
              options: null,
              placeholder: "€",
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Market Value",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "market",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "DATE_PICKER",
              html: null,
              fieldUI: "valuation-date",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Valuation Date DD/MM/YYYY",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "market",
              name: null,
              unsetDefaultValue: true,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "is-suitable-for-mortgage",
              options: ["Yes", "No"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label:
                "Is the property in its current state suitable for a mortgage?",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "market",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "TEXT_AREA",
              html: null,
              fieldUI: "if-no",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "If 'No', details:",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "market",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "conditional",
                    conditionFieldUI: "is-suitable-for-mortgage",
                    conditionValue: ["No"],
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "could-property-be-disposed",
              options: ["Yes", "No"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label:
                "Could the property be readily disposed of at your valuation?",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "TEXT_AREA",
              html: null,
              fieldUI: "disposed-details",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "If 'No', details:",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "conditional",
                    conditionFieldUI: "could-property-be-disposed",
                    conditionValue: ["No"],
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "estimated-time",
              options: null,
              placeholder: "months",
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Estimated time to sell on the open market",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "future-value",
              options: ["Yes", "No"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label:
                "Are there any factors in the short term that would have an adverse effect on future value?",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "TEXT_AREA",
              html: null,
              fieldUI: "future-value-details",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "If 'Yes', details:",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "conditional",
                    conditionFieldUI: "future-value",
                    conditionValue: ["Yes"],
                    value: ["this field is required"]
                  }
                ]
              }
            }
          ]
        },
        {
          name: "Valuation Commentary",
          fieldsGroups: [
            {
              fieldGroupUI: "price_m2",
              meta: '{"className": "flex justify-between mb-4"}'
            }
          ],
          subPanels: [
            {
              subPanelUI: "assumptions",
              meta: '{"formLayoutClassName": "w-full flex"}',
              subPanelBreak: 0
            },
            {
              subPanelUI: "comparable-1",
              meta: '{"className": "flex justify-between mb-4"}',
              subPanelBreak: 5
            },
            {
              subPanelUI: "comparable-2",
              meta: '{"className": "flex justify-between mb-4"}',
              subPanelBreak: 5
            },
            {
              subPanelUI: "comparable-3",
              meta: '{"className": "flex justify-between mb-4"}',
              subPanelBreak: 5
            }
          ],
          panelBreak: null,
          fields: [
            {
              fieldType: "TEXT",
              html: "<div><span class='text-accent-blue'>NOTE:</span> Please detail any assumptions, special assumptions, reservations, special instructions or departures from Valuation standards.</div>",
              fieldUI: null,
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: null,
              meta: '{"className": "w-full"}',
              icon: null,
              subPanel: "assumptions",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: []
              }
            },
            {
              fieldType: "TEXT_AREA",
              html: null,
              fieldUI: "valuation-commentary",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "",
              meta: '{"className":"mb-4 w-full"}',
              icon: null,
              subPanel: "assumptions",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: []
              }
            },
            {
              fieldType: "TEXT",
              html: "<span class='text-accent-blue'>Comparable 1</span>",
              fieldUI: null,
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: null,
              meta: '{"className":"mb-4"}',
              icon: null,
              subPanel: "comparable-1",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "SELECT",
              html: null,
              fieldUI: "comparable-1-property-type",
              options: [
                "Detached",
                "Semi Detached",
                "Mid-Terrace",
                "End of Terrace",
                "Cottage",
                "Duplex",
                "Apartment",
                "Penthouse",
                "Bungalow",
                "Dormer",
                "Other"
              ],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Property Type",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-1",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "comparable-1-address",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Address",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-1",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "comparable-1-sales-price",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Sales Price",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-1",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "comparable-1-source",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Source",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-1",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "comparable-1-date-of-transaction",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Date of Transaction DD/MM/YYYY",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-1",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "comparable-1-m2",
              options: null,
              placeholder: "E",
              group: "price_m2",
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "m2",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-1",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              fieldSubType: "price",
              html: null,
              fieldUI: "comparable-1-eur-m2",
              options: null,
              placeholder: null,
              group: "price_m2",
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "€ per m2",
              meta: '{"className":"mb-4 ml-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-1",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "SELECT",
              html: null,
              fieldUI: "comparable-1-ber",
              options: [
                "N/A",
                "A1",
                "A2",
                "A3",
                "B1",
                "B2",
                "B3",
                "C1",
                "C2",
                "C3",
                "D1",
                "D2",
                "E1",
                "E2",
                "F",
                "G"
              ],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Building Energy Rating",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-1",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "TEXT_AREA",
              html: null,
              fieldUI: "comparable-1-subject-property",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Comparision to subject property",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-1",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "TEXT",
              html: "<span class='text-accent-blue'>Comparable 2</span>",
              fieldUI: null,
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: null,
              meta: '{"className":"mb-4"}',
              icon: null,
              subPanel: "comparable-2",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "SELECT",
              html: null,
              fieldUI: "comparable-2-property-type",
              options: [
                "Detached",
                "Semi Detached",
                "Mid-Terrace",
                "End of Terrace",
                "Cottage",
                "Duplex",
                "Apartment",
                "Penthouse",
                "Bungalow",
                "Dormer",
                "Other"
              ],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Property Type",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-2",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "comparable-2-address",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Address",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-2",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "comparable-2-sales-price",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Sales Price",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-2",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "comparable-2-source",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Source",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-2",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "comparable-2-date-of-transaction",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Date of Transaction DD/MM/YYYY",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-2",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "comparable-2-m2",
              options: null,
              placeholder: "E",
              group: "price_m2",
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "m2",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-2",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              fieldSubType: "price",
              html: null,
              fieldUI: "comparable-2-eur-m2",
              options: null,
              placeholder: null,
              group: "price_m2",
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "€ per m2",
              meta: '{"className":"mb-4 ml-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-2",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "SELECT",
              html: null,
              fieldUI: "comparable-2-ber",
              options: [
                "N/A",
                "A1",
                "A2",
                "A3",
                "B1",
                "B2",
                "B3",
                "C1",
                "C2",
                "C3",
                "D1",
                "D2",
                "E1",
                "E2",
                "F",
                "G"
              ],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Building Energy Rating",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-2",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "TEXT_AREA",
              html: null,
              fieldUI: "comparable-2-subject-property",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Comparision to subject property",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-2",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "TEXT",
              html: "<span class='text-accent-blue'>Comparable 3</span>",
              fieldUI: null,
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: null,
              meta: '{"className":"mb-4"}',
              icon: null,
              subPanel: "comparable-3",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "SELECT",
              html: null,
              fieldUI: "comparable-3-property-type",
              options: [
                "Detached",
                "Semi Detached",
                "Mid-Terrace",
                "End of Terrace",
                "Cottage",
                "Duplex",
                "Apartment",
                "Penthouse",
                "Bungalow",
                "Dormer",
                "Other"
              ],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Property Type",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-3",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "comparable-3-address",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Address",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-3",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "comparable-3-sales-price",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Sales Price",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-3",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "comparable-3-source",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Source",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-3",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "comparable-3-date-of-transaction",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Date of Transaction DD/MM/YYYY",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-3",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "comparable-3-m2",
              options: null,
              placeholder: "E",
              group: "price_m2",
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "m2",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-3",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              fieldSubType: "price",
              html: null,
              fieldUI: "comparable-3-eur-m2",
              options: null,
              placeholder: null,
              group: "price_m2",
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "€ per m2",
              meta: '{"className":"mb-4 ml-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-3",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "SELECT",
              html: null,
              fieldUI: "comparable-3-ber",
              options: [
                "N/A",
                "A1",
                "A2",
                "A3",
                "B1",
                "B2",
                "B3",
                "C1",
                "C2",
                "C3",
                "D1",
                "D2",
                "E1",
                "E2",
                "F",
                "G"
              ],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Building Energy Rating",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-3",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "TEXT_AREA",
              html: null,
              fieldUI: "comparable-3-subject-property",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Comparision to subject property",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comparable-3",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            }
          ]
        },
        {
          name: "Valuer Sign Off",
          panelBreak: 4,
          subPanels: [
            {
              subPanelUI: "text",
              meta: null,
              subPanelBreak: 0
            },
            {
              subPanelUI: "fields",
              meta: null,
              subPanelBreak: 3
            },
            {
              subPanelUI: "comments",
              meta: null,
              subPanelBreak: 0
            },
            {
              subPanelUI: "session",
              meta: '{"formLayoutClassName": "", "formLayoutWrapperClassName": "mt-3 p-0" }',
              subPanelBreak: 0
            },
            {
              subPanelUI: "certificate",
              meta: '{"formLayoutClassName": "", "formLayoutWrapperClassName": "mt-3 p-0" }',
              subPanelBreak: 0
            },
          ],
          fields: [
            {
              fieldType: "TEXT",
              html: "<div><span class='text-accent-blue'>NOTE: </span> For the purpose of this valuation “Market Value” is defined as ‘the estimated amount for which an asset or liability should exchange on the valuation date between a willing buyer and a willing seller in an arm’s length transaction, after proper marketing and where the parties had each acted knowledgeably, prudently and without compulsion. I certify that the property described in this report has been inspected by me on the date of the inspection specified below, that I have the knowledge, skills, and understanding to undertake the valuation competently, that I have had full access to the property and that I have valued the property and prepared the report in accordance with International Valuation Standards and the RICS ‘Red Book’ or the TEGoVA ‘Blue Book’.</div><br>",
              fieldUI: null,
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: null,
              meta: null,
              icon: null,
              subPanel: "text",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "TEXT",
              html: "<div>I certify that neither I, nor my employees, have a conflict of interest (as outlined in the terms of appointment to the lender’s valuation panel) in respect of this property. I certify that this firm holds up to date Professional Indemnity Insurance for a minimum of €1,000,000. A current photograph of the property is enclosed. This valuation report is addressed to the Lender, which proposes to provide the above named applicants with a mortgage loan secured on the above property, namely to Finance Ireland Credit Solutions DAC, trading as Finance Ireland Residential Mortgages, Finance Ireland Agri, Finance Ireland Leasing, Finance Ireland Commercial Mortgages and Finance Ireland.</div>",
              fieldUI: null,
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: null,
              meta: null,
              icon: null,
              subPanel: "text",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "name-of-valuer",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Name of Valuer",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "fields",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "SIGNATURE",
              html: null,
              fieldUI: "signature-of-valuer",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Signature of Valuer",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "fields",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "TEXT_AREA",
              html: null,
              fieldUI: "company-name-address",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Company Name & Address",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "fields",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "RADIO_GROUP",
              html: null,
              fieldUI: "is-final-inspection-required",
              options: ["Yes", "No"],
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Is a Final Inspection & Report Required",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "fields",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "INPUT",
              html: null,
              fieldUI: "qualification-of-valuer",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Qualification of Valuer",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "fields",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "DATE_PICKER",
              html: null,
              fieldUI: "date-of-report",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Date of Report DD/MM/YYYY",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "fields",
              name: null,
              unsetDefaultValue: true,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "TEXT_AREA",
              html: null,
              fieldUI: "additional_location",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Additional Location Comments (if relevant)",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "comments",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: []
              }
            },
            {
              fieldType: "SESSION_INFO",
              html: null,
              fieldUI: "email-of-user",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Email of user",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "session",
              name: null,
            },
            {
              fieldType: "SESSION_INFO",
              html: null,
              fieldUI: "token-of-user",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Token of user",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "session",
              name: null,
            },
            {
              fieldType: "SESSION_INFO",
              html: null,
              fieldUI: "signing-date-of-report",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "Signing date",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "session",
              name: null,
            },
            {
              fieldType: "SESSION_INFO",
              html: null,
              fieldUI: "ip-of-user",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: "IP of user",
              meta: '{"className":"mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "session",
              name: null,
            },
            {
              fieldType: "CERTIFICATE",
              html: null,
              fieldUI: null,
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: null,
              meta: null,
              icon: null,
              subPanel: "certificate",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
          ],
          groups: null,
        },
        {
          name: "Legal Disclaimer - Strictly Private & Confidential",
          panelBreak: 1,
          fields: [
            {
              fieldType: "TEXT",
              html: "<div>These materials have been provided to you by Finance Ireland Residential Mortgages for information purposes only. They should not be used or relied upon for any purpose. In addition, these materials may not be disclosed, in whole or in part, or summarized or otherwise referred to except as agreed in writing by Finance Ireland Residential Mortgages. The information used in preparing these materials was believed to be correct at the time of publication. Finance Ireland Residential Mortgages assumes no responsibility for independent verification of such information and has relied on such information being complete and accurate in all material respects. To the extent such information includes estimates and forecasts of future financial performance (including estimates of potential cost savings and synergies) prepared by or reviewed or discussed with the managements of your company and/or other potential transaction participants or obtained from public sources, we have assumed that such estimates and forecasts have been reasonably prepared on bases reflecting the best currently available estimates and judgments of such</div>",
              fieldUI: null,
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: null,
              meta: null,
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "TEXT",
              html: "<div>managements (or, with respect to estimates and forecasts obtained from public sources, represent reasonable estimates). These materials were designed for use by specific persons familiar with the business and the affairs of your company and Finance Ireland Residential Mortgages assumes no obligation to update or otherwise revise these materials. Nothing contained herein should be construed as tax, accounting or legal advice.</div>",
              fieldUI: null,
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: null,
              meta: null,
              icon: null,
              subPanel: null,
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
          ],
          subPanels: null,
          fieldsGroups: null
        },
        {
          name: "Report Completion Notes",
          subPanels: [
            {
              subPanelUI: "images",
              meta: '{"formLayoutClassName": "lg:grid lg:grid-cols-3"}',
              subPanelBreak: null
            }
          ],
          fields: [
            {
              fieldType: "TEXT",
              html: "<div><span class='text-accent-blue'>NOTE</span>: It is a requirement that photographs are provided for front, rear, kitchen, bathroom, bedroom(s) and street view. These must be submitted with each valuation. Please load your images to the photograph template below by clicking on the icons. Click <b>Save</b> to upload images and complete the form.</div>",
              fieldUI: null,
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: null,
              meta: '{"className":"col-span-3", "isMediumFont":true}',
              icon: null,
              subPanel: "images",
              name: null,
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "required",
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "IMAGE",
              html: null,
              fieldUI: "front-elevation-image",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: null,
              meta: '{"className":"mt-4 mr-4 mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "images",
              name: "Front Elevation *",
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "conditional",
                    conditionFieldUI: "property-type-a",
                    conditionValue: ["Second hand"],
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "IMAGE",
              html: null,
              fieldUI: "rear-elevation-image",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: null,
              meta: '{"className":"mt-4 mr-4 mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "images",
              name: "Rear Elevation *",
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "conditional",
                    conditionFieldUI: "property-type-a",
                    conditionValue: ["Second hand"],
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "IMAGE",
              html: null,
              fieldUI: "kitchen-image",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: null,
              meta: '{"className":"mt-4 mr-4 mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "images",
              name: "Kitchen *",
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "conditional",
                    conditionFieldUI: "property-type-a",
                    conditionValue: ["Second hand"],
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "IMAGE",
              html: null,
              fieldUI: "living-room-image",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: null,
              meta: '{"className":"mt-4 mr-4 mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "images",
              name: "Living Room *",
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "conditional",
                    conditionFieldUI: "property-type-a",
                    conditionValue: ["Second hand"],
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "IMAGE",
              html: null,
              fieldUI: "main-bathroom-image",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: null,
              meta: '{"className":"mt-4 mr-4 mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "images",
              name: "Main Bathroom *",
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "conditional",
                    conditionFieldUI: "property-type-a",
                    conditionValue: ["Second hand"],
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "IMAGE",
              html: null,
              fieldUI: "bedroom-1-image",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: null,
              meta: '{"className":"mt-4 mr-4 mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "images",
              name: "Bedroom 1 *",
              validation: {
                validationType: "STRING",
                parameters: [
                  {
                    type: "conditional",
                    conditionFieldUI: "property-type-a",
                    conditionValue: ["Second hand"],
                    value: ["this field is required"]
                  }
                ]
              }
            },
            {
              fieldType: "IMAGE",
              html: null,
              fieldUI: "bedroom-2-image",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: null,
              meta: '{"className":"mt-4 mr-4 mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "images",
              name: "Bedroom 2",
              validation: {
                validationType: "STRING",
                parameters: []
              }
            },
            {
              fieldType: "IMAGE",
              html: null,
              fieldUI: "other-image",
              options: null,
              placeholder: null,
              group: null,
              defaultCheckedIndex: null,
              defaultValue: null,
              label: null,
              meta: '{"className":"mt-4 mr-4 mb-4", "isMediumFont":true}',
              icon: null,
              subPanel: "images",
              name: "Other",
              validation: {
                validationType: "STRING",
                parameters: []
              }
            }
          ],
          fieldsGroups: null,
          panelBreak: null
        }
      ]
    }
  }
};

// Keeping this here in case we need to modifiy this form and prepare the data for the GraphQL Mutation.
// eslint-disable-next-line no-unused-vars
const forGraphQLMutation = () => {
  const rawFieldsKeys = [];
  const rawGroupKeys = [];
  const rawSubPanelKeys = [];

  fake_valuation_form_response.data.valuationForm.panels.forEach(panel => {
    panel.fieldsGroups?.forEach(fieldGroup => {
      rawGroupKeys.push(...Object.keys(fieldGroup));
    });
    panel.subPanels?.forEach(subPanel => {
      rawSubPanelKeys.push(...Object.keys(subPanel));
    });
    panel.fields.forEach(fields => {
      rawFieldsKeys.push(...Object.keys(fields));
    });
  });
  const fieldsKeys = [...new Set(rawFieldsKeys)];
  const groupsKeys = [...new Set(rawGroupKeys)];
  const subPanelKeys = [...new Set(rawSubPanelKeys)];

  const panels = fake_valuation_form_response.data.valuationForm.panels.map(
    panel => ({
      ...panel,
      subPanels:
        panel.subPanels?.map(subPanel =>
          subPanelKeys.reduce((acc, key) => {
            acc[key] = subPanel[key] ?? null;
            return acc;
          }, {})
        ) || null,
      fieldsGroups:
        panel.fieldsGroups?.map(group =>
          groupsKeys.reduce((acc, key) => {
            acc[key] = group[key] ?? null;
            return acc;
          }, {})
        ) || null,
      panelBreak: panel.panelBreak || null,
      fields: panel.fields.map(field =>
        fieldsKeys.reduce((acc, key) => {
          acc[key] = field[key] ?? null;
          return acc;
        }, {})
      )
    })
  );

  fake_valuation_form_response.data.valuationForm.panels = panels;
  return fake_valuation_form_response;
};
// console.log("forGraphQLMutation", JSON.stringify(forGraphQLMutation()));
