import { string, func } from "prop-types";

const Checkbox = ({ name, label, id, register, handleChange, ...props }) => {
    const changeHandler = (e) => {
        if (handleChange) {
            handleChange(name, e.target.checked);
        }
    };

  return (
    <div className="flex items-center">
      <input
        id={id}
        type="checkbox"
        className="form-checkbox h-4 w-4 text-accent-blue transition duration-150 ease-in-out"
        name={name}
        onChange={changeHandler}
        ref={register}
        {...props}
      />
      <label
        htmlFor={id}
        className="ml-3 block text-base leading-5 text-gray-4"
      >
        {label}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  name: string,
  id: string,
  label: string,
  handleChange: func,
};

export default Checkbox;
