import FAKE_TYPES from "../data/fake_valuationsTypes";
import { FORM_INPUT_IDS } from "../store/CreateValuationStore";
import { initializeApollo } from "../apiService/apolloClient";
import {
  GET_VALUATION_BY_ID_QUERY,
  CREATE_VALUATION,
  UPDATE_VALUATION,
  CHANGE_VALUATION_STATUS,
  CREATE_VALUATION_NOTE,
  ALL_VALUATIONS_BY_USER
} from "../graphql/valuation";
import { ALL_BANK_USERS_QUERY } from "../graphql/bank";

// TODO: need to add broker and underwriter related fields on backend, valuationType is hardcoded for now
const getValuationInput = (data, locationData = {}) => {
  const formattedData = {
    dueDate: data[FORM_INPUT_IDS.DUE_DATE],
    broker: data[FORM_INPUT_IDS.BROKER],
    securityAddress: data[FORM_INPUT_IDS.SECURITY_ADDRESS],
    brokerEmail: data[FORM_INPUT_IDS.BROKER_EMAIL],
    notifyBroker: data[FORM_INPUT_IDS.NOTIFY_BROKER],
    valuationType: data[FORM_INPUT_IDS.VALUATION_TYPE],
    valuerId: data.valuerId,
    customerName: data[FORM_INPUT_IDS.CUSTOMER_NAME],
    sellingAgent: data[FORM_INPUT_IDS.NAME],
    sellingAgentEmail: data[FORM_INPUT_IDS.EMAIL],
    sellingAgentPhone: data[FORM_INPUT_IDS.PHONE],
    notes: data[FORM_INPUT_IDS.NOTES],
    expectedMarketValue: data[FORM_INPUT_IDS.EXPECTED_MARKET_VALUE],
    propertyAddress: data[FORM_INPUT_IDS.PROPERTY_ADDRESS],
    propertyLocationLatitude: data[FORM_INPUT_IDS.PROPERTY_LATITUDE],
    propertyLocationLongitude: data[FORM_INPUT_IDS.PROPERTY_LONGITUDE],
    organisationId: data[FORM_INPUT_IDS.ORGANIZATION_ID],
    companyId: data[FORM_INPUT_IDS.COMPANY_ID],
    companyOfficeId: data[FORM_INPUT_IDS.OFFICE_ID],
    underwriterId: data[FORM_INPUT_IDS.UNDERWRITER_ID]
      ? Number(data[FORM_INPUT_IDS.UNDERWRITER_ID])
      : undefined,
    propertyNeighborhood:
      data[FORM_INPUT_IDS.PROPERTY_NEIGHBORHOOD] ||
      locationData?.locality ||
      locationData?.neighborhood ||
      locationData?.administrative_area_level_1,
    propertyCity:
      data[FORM_INPUT_IDS.PROPERTY_CITY] ||
      locationData?.administrative_area_level_1,
    propertyRegion:
      data[FORM_INPUT_IDS.PROPERTY_REGION] ||
      locationData?.administrative_area_level_1 ||
      "",
    eircode:
    data[FORM_INPUT_IDS.PROPERTY_EIR_CODE] ||
    locationData?.postal_code,
    propertyCaseNumber: data[FORM_INPUT_IDS.PROPERTY_CASE_NUMBER],
    propertyImageLinkUrl: data.propertyImageLinkUrl,
    valuationAmount: data.valuationAmount,
    slaPauseComment: data.slaPauseComment,
    slaPaused: data.slaPaused,
    attachedFiles: data[FORM_INPUT_IDS.ATTACHED_FILES] , 
    propertyImage:
      data[FORM_INPUT_IDS.PROPERTY_IMAGE_FILENAME] &&
      data[FORM_INPUT_IDS.PROPERTY_IMAGE_URL]
        ? {
            filename: data[FORM_INPUT_IDS.PROPERTY_IMAGE_FILENAME],
            url: FORM_INPUT_IDS.PROPERTY_IMAGE_URL
          }
        : undefined,

    completedValuationDocument:
      data[FORM_INPUT_IDS.COMPLETED_VALUATION_DOCUMENT_FILENAME] &&
      data[FORM_INPUT_IDS.COMPLETED_VALUATION_DOCUMENT_URL]
        ? {
            filename:
              data[FORM_INPUT_IDS.COMPLETED_VALUATION_DOCUMENT_FILENAME],
            url: data[FORM_INPUT_IDS.COMPLETED_VALUATION_DOCUMENT_URL]
          }
        : undefined
  };

  if (data[FORM_INPUT_IDS.VALUATION_ID]) {
    return { ...formattedData, id: data[FORM_INPUT_IDS.VALUATION_ID] };
  }

  return formattedData;
};

const ValuationService = {
  async updateValuationStatus({ id, status, message = "" }) {
    const apolloClient = initializeApollo();

    return await apolloClient.mutate({
      mutation: CHANGE_VALUATION_STATUS,
      variables: {
        input: { id, newStatus: status, message }
      },
      refetchQueries: [
        {
          query: GET_VALUATION_BY_ID_QUERY,
          variables: { id }
        },
        {
          query: ALL_VALUATIONS_BY_USER
        }
      ]
    });
  },

  async createValuationNote(data) {
    const apolloClient = initializeApollo();

    return await apolloClient.mutate({
      mutation: CREATE_VALUATION_NOTE,
      variables: {
        input: data
      },
      refetchQueries: [
        {
          query: GET_VALUATION_BY_ID_QUERY,
          variables: { id: data.valuationId }
        }
      ]
    });
  },

  async createValuation(data, locationData) {
    const valuationInput = getValuationInput(data, locationData);
    const apolloClient = initializeApollo();
    // delete bellow string here when api is ready
    // delete valuationInput.attachedFiles

    return await apolloClient.mutate({
      mutation: CREATE_VALUATION,
      variables: {
        input: valuationInput
      },
      refetchQueries: [{ query: ALL_VALUATIONS_BY_USER }]
    });
  },

  async updateValuation(data) {
    const valuationInput = getValuationInput(data);
    const apolloClient = initializeApollo();

    const updated = await apolloClient.mutate({
      mutation: UPDATE_VALUATION,
      variables: {
        input: valuationInput
      },
      refetchQueries: [
        {
          query: GET_VALUATION_BY_ID_QUERY,
          variables: { id: valuationInput.id }
        }
      ]
    });
    return updated;
  },

  async updateInspectionDate(data) {
    const apolloClient = initializeApollo();

    return await apolloClient.mutate({
      mutation: UPDATE_VALUATION,
      variables: {
        input: {
          id: data.valuationId,
          inspectionDate: data.inspectionDate
        }
      },
      refetchQueries: [
        {
          query: GET_VALUATION_BY_ID_QUERY,
          variables: { id: data.valuationId }
        }
      ]
    });
  },

  async getTypes() {
    return new Promise((resolve, reject) =>
      setTimeout(() => resolve(FAKE_TYPES), 1000)
    );
  },

  async getUnderwriters(bankId) {
    const apolloClient = initializeApollo();

    return await apolloClient.query({
      query: ALL_BANK_USERS_QUERY,
      variables: {
        bankId: bankId
      }
    });
  }
};

export default ValuationService;
