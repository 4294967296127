import { useLocalObservable } from "mobx-react-lite";
import CompanyService from "service/company-service";
import ValuationService from "service/valuation-service";
import { ERROR_LOADING_MESSAGES } from "../constants";
import { getEmptyFields } from "../helpers"

export const FORM_INPUT_IDS = {
  VALUATION_ID: "valuationId",
  PROPERTY_ADDRESS: "propertyAddress",
  ORGANIZATION_ID: "organisationId",
  COMPANY_ID: "companyId",
  OFFICE_ID: "companyOfficeId",
  PROPERTY_LATITUDE: "propertyLocationLatitude",
  PROPERTY_LONGITUDE: "propertyLocationLongitude",
  PROPERTY_REGION: "propertyRegion",
};

const ChangeCompanyOfficeStore = () => {
  return useLocalObservable(() => ({
    valuationId: null,
    offices: null,
    position: null,
    locationData: null,
    selectedOffice: null,
    companiesErrorMessage: false,
    typesErrorMessage: false,
    loading: false,

    data: getEmptyFields(FORM_INPUT_IDS),

    resetValuation() {
      this.resetErrors();
      this.valuationId = null;
      this.offices = null;
      this.position = null;
      this.locationData = null;
      this.selectedOffice = null;
      this.companiesErrorMessage = false;
      this.typesErrorMessage = false;
      this.loading = false;
      this.data = getEmptyFields(FORM_INPUT_IDS);
    },

    setLoading(value) {
      this.loading = value;
    },

    setCurrentValuation({ valuation, organisationId }) {
      this.data[FORM_INPUT_IDS.ORGANIZATION_ID] = organisationId;
      this.data[FORM_INPUT_IDS.COMPANY_ID] = valuation?.company?.id
      this.data[FORM_INPUT_IDS.OFFICE_ID] = valuation?.office?.id

      this.data[FORM_INPUT_IDS.VALUATION_ID] = valuation?.id;
      this.data[FORM_INPUT_IDS.PROPERTY_LONGITUDE] = valuation?.propertyLocationLongitude;
      this.data[FORM_INPUT_IDS.PROPERTY_LATITUDE] = valuation?.propertyLocationLatitude;
      this.position = {location: {lat: Number(valuation?.propertyLocationLatitude), lng: Number(valuation?.propertyLocationLongitude)}};
      this.data[FORM_INPUT_IDS.PROPERTY_ADDRESS] = valuation?.propertyAddress;
      this.data[FORM_INPUT_IDS.PROPERTY_REGION] = valuation?.propertyRegion;
      this.selectedOffice = {
        id: valuation?.office?.id || null,
        officeLatitude: Number(valuation?.office?.latitude) || "",
        organisationName: valuation?.organisation?.name || "Independent",
        officeLongitude: Number(valuation?.office?.longitude) || "",
        companyId: valuation?.company?.id || "",
        companyName: valuation?.company?.name || "-",
        officeName: valuation?.office?.name || "-",
        officePhone: valuation?.office?.phone || "-",
        officeEmail: valuation?.office?.email || "-",
      }
      this.getOffices(this.data[FORM_INPUT_IDS.ORGANIZATION_ID], valuation.bank?.id);
    },

    resetErrors() {
      this.officesErrorMessage = false;
      this.typesErrorMessage = false;
    },

    changeProperty(property, value) {
      this.data[property] = value;
    },

    changeOffice(companyId, officeId, organizationId) {
      this.data[FORM_INPUT_IDS.COMPANY_ID] = companyId;
      this.data[FORM_INPUT_IDS.OFFICE_ID] = officeId;
      this.data[FORM_INPUT_IDS.ORGANIZATION_ID] = organizationId;
    },

    resetOffice() {
      this.data[FORM_INPUT_IDS.COMPANY_ID] = null;
      this.data[FORM_INPUT_IDS.OFFICE_ID] = null;
      this.resetErrors();
    },

    setSelectedOption(office) {
      this.selectedOffice = office;
    },

    async getOffices(organisationId, bankId) {
      try {
        this.offices = await CompanyService.getCompanyOfficesByLocation({
          location: this.position.location,
          radius: 999999,
          organisationId,
          bankId,
        });
      } catch (e) {
        this.officesErrorMessage = ERROR_LOADING_MESSAGES.AGENT;
      }
    },

    async updateValuation() {
      try {
        this.setLoading(true);
        return await ValuationService.updateValuation(this.data);
      } catch(err) {
        this.setLoading(false);
        this.typesErrorMessage = err.message;
      }
    },
  }));
};

export default ChangeCompanyOfficeStore;
