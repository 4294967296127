import { RequireNewPassword } from 'aws-amplify-react';
import { LoginLayout } from './components/LoginLayout';
import PasswordField from './components/PasswordField';
import PasswordVerification from './components/PasswordVerification'
import Button from "components/Button/Button";
import { checkThePassword } from 'constants/index';
import Spinner from 'components/Spinner/Spinner';

export class MyRequireNewPassword extends RequireNewPassword {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      password: '',
      passwordCheck: '',
      loading: false
    };
  }

  onInputChange = (event) => {
    if (this.state.error !== '') {
      this.setState(() => ({ error: '' }));
    }
    this.handleInputChange(event)
  }

  errorMessage = (err) => {
    this.setState(() => ({ error: err.message }));
  }

  handleSendPass = async (ev) => {
    ev.preventDefault()
    const passwordValue = document.querySelector('[name="password"]').value
    const confirmedPasswordValue = document.querySelector('[name="password-check"]').value
    if (passwordValue !== confirmedPasswordValue) {
      this.setState(() => ({ error: 'Your passwords do not match each other' }))
      return
    }
    await this.change()
    await fetch('/api/confirm-email', {
      method: 'POST',
      body: JSON.stringify({ username: this.props.authData.username })
    })
    if (this.props.authState === 'verifyContact') {
      this.setState(() => ({ loading: true }))
      window.location.reload()
    }
  }
  
  render() {
    if (this.props.authState !== "requireNewPassword") return null
    return (
      <>
        {
          this.state.loading
            ? <Spinner />
            : (
              <LoginLayout title={"Enter New Password"} errorMessage={this.state.error}>
                <form className="relative space-y-6 py-6 pt-4 px-8" onSubmit={this.handleSendPass}>
                  <PasswordField
                    defaulValue={''}
                    onChange={(ev) => {
                      this.onInputChange(ev)
                      this.setState(() => ({ password: ev.target.value }))
                    }}
                    id="password"
                    name="password"
                    label="New Password"
                  />
                  <PasswordField
                    defaulValue={''}
                    onChange={(ev) => {
                      this.onInputChange(ev)
                      this.setState(() => ({ passwordCheck: ev.target.value }))
                    }}
                    id="password-check"
                    name="password-check"
                    label="Confirm Your Password"
                  />

                  <PasswordVerification
                    password={this.state.password || ''}
                    areEqual={this.state.password === this.state.passwordCheck && this.state.password !== '' && this.state.passwordCheck !== ''}
                  />
                  
                  <Button
                    type="submit"
                    variant="primary"
                    size="normal"
                    isFullWidth={true}
                    isTextCenter={true}
                    loading={false}
                    disabled={Object.values(checkThePassword(this.state.password)).findIndex(item => !item) !== -1 || this.state.password !== this.state.passwordCheck}
                  >
                    Save the password
                  </Button>
                  <div className='flex justify-center'>
                    <button
                      type='button'
                      className="text-base mx-auto no-underline text-accent-blue visited:text-accent-blue hover:text-indigo-600 focus:outline-none focus:underline transition ease-in-out duration-150"
                      onClick={() => this.changeState('signIn')}
                    >
                      Back to Log In
                    </button>
                  </div>
                </form>
              </LoginLayout>
            )
        }
      </>
    )
  }
}

export default MyRequireNewPassword;
