import { gql } from "@apollo/client";

export const WHOAMI = gql`
  {
    whoami {
      role
      firstName,
      lastName,
      businessSubjectName,
      businessSubjectId
    }
  }
`
