import { initializeApollo } from "../apiService/apolloClient";
import {
  CREATE_VALUATION_FORM_VALUES,
  GET_VALUATION_FORM_VALUES,
  VALUATION_TEMPLATE_QUERY,
  UPDATE_VALUATION_FORM_VALUES
} from "../graphql/valuationForm";
import { fake_valuation_form_response } from "../data/ireland_valuation_form";

export const transformValuationFormValues = arr =>
  arr?.reduce((acc, value) => {
    acc[value.fieldUI] = JSON.parse(value.value);
    return acc;
  }, {});

const BanksService = {
  async getBankValuationForm(id) {
    const apolloClient = initializeApollo();
    let response;
    // When form is added to the db, remove this check and just run the query
    const isFormOnDB = false;
    if (isFormOnDB) {
      try {
        response = await apolloClient.query({
          query: VALUATION_TEMPLATE_QUERY,
          variables: { id }
        });
      } catch (e) {
        // TODO: This should be removed when the form is not longer deleted from the DB.
        console.log("Error getting form template", e);
        response = fake_valuation_form_response;
      }
    }

    response = fake_valuation_form_response;

    const extractMeta = item => {
      if (item.meta) {
        const metafield = JSON.parse(item.meta);
        delete item.meta;
        return metafield;
      }
      return;
    };
    const panels = JSON.parse(
      JSON.stringify(response?.data.valuationForm?.panels)
    );
    const valuationForm = panels.map(form => ({
      ...form,
      subPanels: form.subPanels?.map(subPanel => {
        const metaFields = extractMeta(subPanel);
        return {
          ...subPanel,
          ...metaFields
        };
      }),
      fieldsGroups: form.fieldsGroups?.map(group => {
        const metaFields = extractMeta(group);
        return {
          ...group,
          ...metaFields
        };
      }),
      fields: form.fields.map(field => {
        const metaFields = extractMeta(field);
        Object.keys(field).map(key => {
          if (field[key] === null) delete field[key];
        });
        return {
          ...field,
          ...metaFields
        };
      })
    }));

    return valuationForm;
  },
  async saveValuationForm({ id, valuationId, values, revalidation }) {
    const objectToArray = obj =>
      obj
        ? Object.keys(obj).map(key => ({
            fieldUI: key,
            value: JSON.stringify(obj[key])
          }))
        : [];

    const apolloClient = initializeApollo();

    const valuesInput = {
      id,
      valuationId,
      valuationFormValues: objectToArray(values),
      revalidation: objectToArray(revalidation)
    };
    let mutationResponse;
    if (id) {
      // If we have id, we are updating a previous created one.
      mutationResponse = await apolloClient.mutate({
        mutation: UPDATE_VALUATION_FORM_VALUES,
        variables: {
          input: valuesInput
        }
      });
    } else {
      // If we don't have id, we should create a new valuation form
      mutationResponse = await apolloClient.mutate({
        mutation: CREATE_VALUATION_FORM_VALUES,
        variables: {
          input: valuesInput
        }
      });
    }
    return mutationResponse;
  },
  async getValuationFormValues(id, setError) {
    try {
      const apolloClient = initializeApollo();
      const response = await apolloClient.query({
        query: GET_VALUATION_FORM_VALUES,
        variables: { id: id },
        fetchPolicy: "no-cache"
      });

      const responseId = response?.data?.valuationFormValues?.id;
      const revalidation = response?.data?.valuationFormValues?.revalidation;
      const values = response?.data?.valuationFormValues?.valuationFormValues;

      return {
        id: responseId,
        savedValues: transformValuationFormValues(values),
        revalidation: transformValuationFormValues(revalidation)
      };
    } catch (error) {
      setError(error.message);
      console.error("getValuationFormValues error", error);
    }
  }
};

export default BanksService;
