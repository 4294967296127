import { ForgotPassword } from 'aws-amplify-react';
import { LoginLayout } from './components/LoginLayout';
import PasswordField from './components/PasswordField';
import PasswordVerification from './components/PasswordVerification'
import Input from "components/Input/Input";
import Button from "components/Button/Button";
import { checkThePassword } from 'constants/index';

export class MyForgotPassword extends ForgotPassword {
  state = {
    error: '',
    password: '',
    passwordCheck: ''
  }

  errorMessage = (err) => {
    this.setState(() => ({ error: err.message }));
  }

  onInputChange = (event) => {
    if (this.state.error !== '') {
      this.setState(() => ({ error: '' }));
    }
    this.handleInputChange(event)
  }

  handleSendPass = async (event) => {
    event.preventDefault()
    if (!this.state.delivery) {
      await this.send()
    } else {
      const passwordValue = document.querySelector('[name="password"]').value
      const confirmedPasswordValue = document.querySelector('[name="password-check"]').value
      if (passwordValue !== confirmedPasswordValue) {
        this.setState(() => ({ error: 'Your passwords do not match each other' }))
        return
      }
      await this.submit(); 
    }
  }

  
  render() {
    if (this.props.authState !== "forgotPassword") return null 
    return (
      <LoginLayout title={"Reset Your Password"} errorMessage={this.state.error}>
        <form className="relative space-y-6 py-6 pt-4" onSubmit={this.handleSendPass}>
          {
            !this.state.delivery && (
              <div className='px-8'>
                <Input
                  label="Email Address"
                  isMediumFont={true}
                  id="username"
                  name="username"
                  required={true}
                  onChange={this.onInputChange}
                />
              </div>
            )
          }
          {
            this.state.delivery && (
              <>
                <div className='px-8 space-y-6 pb-4 border-b border-b-gray-7'>
                  <Input
                    label="Verification Code"
                    isMediumFont={true}
                    id="code"
                    name="code"
                    inputProps={{
                      key: 'code',
                      name: 'code',
                    }}
                    onFocus={(ev) => ev.target.removeAttribute('readOnly')}
                    readOnly={true}
                    required={true}
                    onChange={this.onInputChange}
                  />
                  <p className='text-gray-6 text-sm'>
                    Enter the security code we just sent to your email address. The codes expire after 24 hours.
                  </p>
                </div>
                <div className='px-8 space-y-6'>
                  <PasswordField
                    defaulValue={''}
                    onChange={(ev) => {
                      this.onInputChange(ev)
                      this.setState(() => ({ password: ev.target.value }))
                    }}
                    id="password"
                    name="password"
                    label="New Password"
                  />
                  <PasswordField
                    defaulValue={''}
                    onChange={(ev) => {
                      this.onInputChange(ev)
                      this.setState(() => ({ passwordCheck: ev.target.value }))
                    }}
                    id="password-check"
                    name="password-check"
                    label="Retype New Password"
                  />
                  <PasswordVerification
                    password={this.state.password || ''}
                    areEqual={this.state.password === this.state.passwordCheck && this.state.password !== '' && this.state.passwordCheck !== ''}
                  />
                </div>
              </>
            )
          }  
          <div className='px-8 space-y-6'>
            <Button
              type="submit"
              variant="primary"
              size="normal"
              isFullWidth={true}
              isTextCenter={true}
              loading={false}
              disabled={this.state.delivery && (Object.values(checkThePassword(this.state.password)).findIndex(item => !item) !== -1 || this.state.password !== this.state.passwordCheck)}
            >
              {this.state.delivery ? 'Submit' : 'Send Verification'}
            </Button>
            <div className='flex justify-center'>
              <button
                type='button'
                className="text-base no-underline text-accent-blue visited:text-accent-blue hover:text-indigo-600 focus:outline-none focus:underline transition ease-in-out duration-150"
                onClick={() => {
                  this.changeState('signIn')
                  this.setState(() =>  ({ delivery: null }))
                }}
              >
                Back to Log In
              </button>
            </div>
          </div>
        </form>
      </LoginLayout>
    )
  }
}

export default MyForgotPassword;