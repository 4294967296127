import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { WHOAMI } from "../graphql/user";
import { useRouter } from "next/router";
import { setCookies } from 'cookies-next';


const UserInfoContext = React.createContext();

export function useUserInfo() {
  return useContext(UserInfoContext);
}

export function UserInfoProvider({ children }) {
  const { data, loading } = useQuery(WHOAMI);
  const router = useRouter();
  
  useEffect(() => {
    if (!loading && !data) {
      const lastAuthUser = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.USER_POOL_CLIENT_ID}.LastAuthUser`)
      if (!lastAuthUser) {
        localStorage.clear();
        router.reload();
      }
      const refreshToken = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.USER_POOL_CLIENT_ID}.${lastAuthUser}.refreshToken`)
      const accessToken = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.USER_POOL_CLIENT_ID}.${lastAuthUser}.accessToken`)
      const idToken = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.USER_POOL_CLIENT_ID}.${lastAuthUser}.idToken`)
      if (refreshToken && accessToken && idToken) {
        setCookies(`CognitoIdentityServiceProvider.${process.env.USER_POOL_CLIENT_ID}.LastAuthUser`, lastAuthUser);
        setCookies(`CognitoIdentityServiceProvider.${process.env.USER_POOL_CLIENT_ID}.${lastAuthUser}.refreshToken`, refreshToken);
        setCookies(`CognitoIdentityServiceProvider.${process.env.USER_POOL_CLIENT_ID}.${lastAuthUser}.accessToken`, accessToken);
        setCookies(`CognitoIdentityServiceProvider.${process.env.USER_POOL_CLIENT_ID}.${lastAuthUser}.idToken`, idToken);
        router.reload();
      } else {
        localStorage.clear();
        router.reload();
      }
    }
  }, [data, loading])

  const value = {
    currentUser: data?.whoami,
    currentUserId: data?.whoami?.businessSubjectId,
    role: data?.whoami?.role
  };

  return (
    <>
      {
        data ? (
          <UserInfoContext.Provider value={value}>
            {!loading && children}
          </UserInfoContext.Provider>
         ) : (
            null
        )
      }
    </>
  );
}
