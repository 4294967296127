import { useState } from 'react';
import { ShowPasswordButton } from "./ShowPasswordButton";
import Input from "components/Input/Input";

const PasswordField = ({ defaulValue, onChange, label='', id, name,  }) => {
  const [isShowPassword, setIsShowPassword] = useState(false);

  return (
    <div className='relative flex items-end w-full'>
      <div className='w-full'>
        <Input
          className="grow-1 w-full border-r-0 rounded-br-none h-10 rounded-tr-none mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400"
          label={label}
          isMediumFont={true}
          id={id}
          name={name}
          defaultValue={defaulValue}
          inputprops={{
            key: 'password',
            name: 'password',
          }}
          type={isShowPassword ? 'text' : 'password'}
          required={true}
          onChange={onChange}
          autoComplete="off"
          onFocus={(ev) => ev.target.removeAttribute('readOnly')}
          readOnly={true}
        />
      </div>
      <ShowPasswordButton
        onClick={() => setIsShowPassword(!isShowPassword)}
        hidden={isShowPassword}
      />
    </div>
  )
}

export default PasswordField;