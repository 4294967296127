import { useState } from "react";

import { useUserInfo } from "hocs/UserInfoContext";
import Button from "components/Button/Button";
import Modal from "components/Modal/Modal";

const TermsAndConditions = () => {
  const [isOpen, setIsOpen] = useState(
    !Boolean(localStorage.getItem("isUserAgreedToTermsAndConditions"))
  );
  const [isAgree, setIsAgree] = useState(false);
  
  const { currentUser } = useUserInfo();
  
  const handleContinue = () => {
    localStorage.setItem("isUserAgreedToTermsAndConditions", "true");
    
    setIsOpen(false);
  }
  
  return (
    <Modal size="sm" isOpen={isOpen}>
      <div className="bg-white pt-14 pb-9 px-8 w-full max-w-modal" data-testid="terms-conditions-popup">
        <div className="flex justify-center items-center mb-11">
          <img
            src="/image/logo.svg"
            alt="central valuation"
            draggable="false"
            className="w-12 h-12 mr-4"
          />
          <h3
            className="text-2xl leading-6 font-semibold text-black"
            id="modal-title"
          >
            Central
            <br />
            Valuations
          </h3>
        </div>
        <div className="mb-5 ">
          <p className="leading-normal">
            Welcome
            {` ${currentUser.firstName}`}
            . Please review and agree to our terms & conditions and privacy policy before proceeding.
          </p>
        </div>
        <div className="flex justify-center items-center mb-6">
          <input
            type="checkbox"
            className="mr-4"
            checked={isAgree}
            onChange={() => setIsAgree(prevState => !prevState)}
            data-testid="terms-modal-checkbox"
          />
          <p>
            I agree to the
            {' '}
            <a
              className="text-base text-accent-blue"
              href="https://centralvaluations.ie/cookies"
              target="_blank"
            >
              terms & conditions
            </a>
            {' '}
            and
            {' '}
            <a
              className="text-base text-accent-blue"
              href="https://centralvaluations.ie/privacy"
              target="_blank"
            >
              privacy policy
            </a>
          </p>
        </div>
        <div className="" data-testid="terms-bubbon-wrapper">
          <Button
            variant="primary"
            disabled={!isAgree}
            isFullWidth
            isTextCenter
            onClick={handleContinue}
          >
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default TermsAndConditions;