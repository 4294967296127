import { Loading } from 'aws-amplify-react';
import Spinner from 'components/Spinner/Spinner';


export class MyLoading extends Loading {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    if (this.props.authState !== "loading" && this.props.authState !== "verifyContact") return null
    return (
      <div className="w-full absolute top-0 left-0">
        <Spinner isLoad={true} />
      </div>
    )
  }
}

export default MyLoading;
