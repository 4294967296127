import StoreContext from "./StoreContext";
import CreateValuationStore from "./CreateValuationStore";
import CreateLoginStore from "./CreateLoginStore";
import ChangeCompanyOfficeStore from "./ChangeCompanyOfficeStore";

export const StoreProvider = ({ children }) => {
  return (
    <StoreContext.Provider
      value={{
        createValuationStore: CreateValuationStore(),
        createLoginStore: CreateLoginStore(),
        ChangeCompanyOfficeStore: ChangeCompanyOfficeStore()
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
