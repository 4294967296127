import React from "react";
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "apiService/apolloClient";
import { StoreProvider } from "../store/store";
import "react-datepicker/dist/react-datepicker.css";
import { UserInfoProvider } from "../hocs/UserInfoContext";
import { Amplify, withSSRContext } from "aws-amplify";
import MySignIn from '../scene/SignIn/MySignIn';
import MyForgotPassword from "scene/SignIn/MyForgotPassword";
import MyRequireNewPassword from "scene/SignIn/MyRequireNewPassword";
import { MyLoading } from "scene/SignIn/MyLoading";
import Spinner from "components/Spinner/Spinner";

import "../styles/globals.css";
import ModalProvider from "hocs/ModalContext";
import ConfirmationModal from "components/modals/ConfirmationModal";
import { withAuthenticator } from 'aws-amplify-react';
import '@aws-amplify/ui-react/styles.css';
import TermsAndConditions from "../components/modals/TermsAndConditions";

const authObj = {
  region: process.env.REGION,
    identityPoolRegion: process.env.REGION,
    userPoolId: process.env.USER_POOL_ID,
    userPoolWebClientId: process.env.USER_POOL_CLIENT_ID,
  oauth: {
    domain: process.env.IDP_DOMAIN,
    scope: ["email", "openid"],
    responseType: "code"
  }
}

Amplify.configure({
  Auth: authObj,
  ssr: true,
});

const { Auth } = withSSRContext();
Auth.configure(authObj);

function MyApp({ Component, pageProps, router }) {
  const apolloClient = useApollo(pageProps);

  return (
    <div>
      {
        pageProps?.isAuth === false && (
          <div className="w-full absolute top-0 left-0">
            <Spinner isLoad={true} />
          </div>)
      }
      <StoreProvider>
          <ApolloProvider client={apolloClient}>
          <UserInfoProvider>
            <ModalProvider>
              <Component {...pageProps} />
                <ConfirmationModal />
                <TermsAndConditions />
              </ModalProvider>
            </UserInfoProvider>
          </ApolloProvider>
      </StoreProvider>
    </div>
  );
}

export default withAuthenticator(MyApp, false, [
  <MySignIn />,
  <MyForgotPassword />,
  <MyRequireNewPassword />,
  <MyLoading />
], null)
